/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext, useState } from 'react'

import { AiOutlineExclamationCircle } from 'react-icons/ai'
// UI
import BodyText2 from './BodyText2'
// Components
import ConditionalRender from '../components/ConditionalRender'
// Contexts
import { ValidationContext } from '../contexts/Validation'
import { CopyContext } from '../contexts/Copy'
import { StylesContext } from '../contexts/Styles'
// Helpers
import { validateSingleField } from '../helpers/validation/main'
import stringReplace from '../helpers/stringReplace'

// Interface
// {
//     autoComplete: string
//     children: React.ReactElement | string
//     className: string
//     inputClassName: string
//     disabled: boolean | undefined
//     name: string
//     onChange: Function
//     placeholder: string
//     isNumberType: boolean
// }

function DropdownV2({
    step = null,
    children,
    className = '',
    errorClassName = '',
    subLabelClass = '',
    selectClassName = '',
    name,
    onChange = () => {},
    validationFunctions = [],
    formDataKey = '',
    disableOnChangeValidation = false,
    disableValidation = false,
    options = [],
    subLabel = '',
    value,
    hideErrorMessage = false,
}: any) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [copy]: any = useContext(CopyContext)
    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [waitToValidate, setWaitToValidate] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if ((step || step === 0) && name && !createdValidation && !disableValidation) {
            validationDispatch({
                type: 'ADD_FIELD',
                payload: {
                    step,
                    name,
                    formDataKey,
                    functions: validationFunctions,
                },
            })
            setCreatedValidation(true)
        }
    }, [createdValidation, formDataKey, name, step, validationFunctions])

    useEffect(() => {
        if ((step || step === 0) && name) {
            if (disableValidation) {
                validationDispatch({
                    type: 'REMOVE_FIELDS',
                    payload: {
                        step,
                        names: [name],
                    },
                })
                validationDispatch({
                    type: 'ADD_ERROR',
                    payload: {
                        [formDataKey]: '',
                    },
                })
            } else {
                validationDispatch({
                    type: 'ADD_FIELD',
                    payload: {
                        step,
                        name,
                        formDataKey,
                        functions: validationFunctions,
                    },
                })
            }
        }
    }, [disableValidation])

    useEffect(() => {
        const asyncWrapper = async () => {
            const { message, metaData } = await validateSingleField(value, validationFunctions)
            setErrorMessage(
                stringReplace(copy?.validationErrors?.[message] ? copy?.validationErrors?.[message] : '', metaData)
            )
        }

        if (waitToValidate && !disableOnChangeValidation && !disableValidation) {
            asyncWrapper()
        }
    }, [value, validationFunctions, waitToValidate, disableOnChangeValidation, copy?.validationErrors])

    useEffect(() => {
        if (validation?.errors?.errorMessages?.[name] || validation?.errors?.errorMessages?.[name] === '') {
            if (errorMessage !== validation.errors.errorMessages[name]) {
                let message = validation.errors.errorMessages[name]
                if (message !== '') {
                    message = stringReplace(
                        copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            ? copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            : '',
                        validation.errors.metaDatas[name]
                    )
                }
                setErrorMessage(message)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation.errors])

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
            className={styles.twMerge(
                styles?.ui?.Dropdown?.label?.mobile,
                styles?.ui?.Dropdown?.label?.tablet,
                styles?.ui?.Dropdown?.label?.desktop,
                className
            )}
        >
            {children}
            <div
                className={
                    subLabel
                        ? styles.twMerge(
                              styles?.ui?.Dropdown?.subLabel?.mobile,
                              styles?.ui?.Dropdown?.subLabel?.tablet,
                              styles?.ui?.Dropdown?.subLabel?.desktop,
                              subLabelClass
                          )
                        : 'hidden'
                }
            >
                {subLabel}
            </div>
            <select
                className={styles.twMerge(
                    styles?.ui?.Dropdown?.select?.mobile,
                    styles?.ui?.Dropdown?.select?.tablet,
                    styles?.ui?.Dropdown?.select?.desktop,
                    styles?.ui?.Dropdown?.select?.active,
                    styles?.ui?.Dropdown?.select?.focus,
                    styles?.ui?.Dropdown?.select?.hover,
                    errorMessage ? styles?.ui?.Dropdown?.select?.error : '',
                    hideErrorMessage ? 'mb-0' : '',
                    selectClassName
                )}
                name={name}
                value={value}
                onChange={(event) => {
                    onChange(event.target.value)
                    if (!waitToValidate) {
                        setWaitToValidate(true)
                    }
                }}
            >
                {options.map((option: { name: string; value: any }) => (
                    <option key={option.name} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
            {!hideErrorMessage && (
                <ConditionalRender condition={errorMessage && !hideErrorMessage}>
                    <BodyText2
                        className={styles.twMerge(
                            styles?.ui?.Dropdown?.error?.mobile,
                            styles?.ui?.Dropdown?.error?.tablet,
                            styles?.ui?.Dropdown?.error?.desktop,
                            errorClassName
                        )}
                    >
                        <AiOutlineExclamationCircle
                            className={styles.twMerge(
                                styles?.ui?.Dropdown?.errorIcon?.mobile,
                                styles?.ui?.Dropdown?.errorIcon?.tablet,
                                styles?.ui?.Dropdown?.errorIcon?.desktop
                            )}
                        />
                        &nbsp;{errorMessage}
                    </BodyText2>
                </ConditionalRender>
            )}
        </label>
    )
}

export default DropdownV2
