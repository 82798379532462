// Packages
import React, { useState } from 'react'
// Forms
import BasicForm from '../BasicForm'

function WelcomeXAddress({ config }: any) {
    const [formName] = useState(config || 'welcomeXAddress')

    return <BasicForm config={formName} />
}

export default WelcomeXAddress
