// Helpers
import initialState from './initialState'

const PopupReducer = (
    state: any,
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'ADD_POPUP': {
            const popupPayload: any = {
                ...action.payload,
            }
            dataFunnelContext.dispatch({ type: 'SET_POPUP', payload: popupPayload })
            dataFunnelContext.dispatch({ type: 'START_MERGED_TIMER', payload: { name: `${popupPayload.name}Popup` } })

            return popupPayload
        }
        case 'REMOVE_POPUP': {
            const popupPayload: any = {
                componentToRender: null,
            }

            if (state.name) {
                dataFunnelContext.dispatch({ type: 'SET_POPUP', payload: popupPayload })
                dataFunnelContext.dispatch({ type: 'END_MERGED_TIMER', payload: { name: `${state.name}Popup` } })
            }

            return popupPayload
        }
        case 'CLEAR_POPUP':
            dataFunnelContext.dispatch({ type: 'SET_POPUP', payload: initialState })
            return initialState
        default:
            dataFunnelContext.dispatch({ type: 'SET_POPUP', payload: state })
            return state
    }
}

export default PopupReducer
