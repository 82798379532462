/* eslint-disable import/prefer-default-export */
import { core as SmartyCore, usStreet as USStreet } from 'smartystreets-javascript-sdk'
import { formatApartmentAddress, formatStreetAddress } from './formatAddress'

interface AddressInput {
    street: string
    secondary?: string
    city?: string
    state?: string
    zipcode?: string
}

const addressValidationCodes: Record<string, string> = {
    AA: 'Street name, city, state, and ZIP are all valid.',
    A1: 'Address not present in USPS data.',
    BB: 'Entire address is valid.',
    CC: 'Secondary information not recognized. Secondary number NOT REQUIRED for delivery.',
    C1: 'Secondary information not recognized. Secondary number IS REQUIRED for delivery.',
    F1: 'Military or diplomatic address.',
    G1: 'General delivery address.',
    M1: 'Primary number (e.g., house number) is missing.',
    M3: 'Primary number (e.g., house number) is invalid.',
    N1: 'Address is missing secondary information required for delivery.',
    PB: 'PO Box street style address.',
    P1: 'PO, RR, or HC box number is missing.',
    P3: 'PO, RR, or HC box number is invalid.',
    RR: 'Confirmed address with private mailbox (PMB) info.',
    R1: 'Confirmed address without private mailbox (PMB) info.',
    R7: "Valid address that doesn't currently receive USPS street delivery.",
    TA: 'Primary number matched by dropping trailing alpha.',
    U1: "Address has a 'unique' ZIP Code.",
}

export function interpretValidationResult(code: string): string[] {
    const descriptions: string[] = []

    // Split the code into chunks of two characters
    for (let i = 0; i < code.length; i += 2) {
        const part = code.slice(i, i + 2)
        if (addressValidationCodes[part]) {
            descriptions.push(`${part}: ${addressValidationCodes[part]}`)
        } else {
            descriptions.push(`${part}: Unknown code.`)
        }
    }

    return descriptions
}

const key: string = process.env.REACT_APP_DIRECT_SMARTY_API_KEY ? process.env.REACT_APP_DIRECT_SMARTY_API_KEY : ''
const credentials = new SmartyCore.SharedCredentials(key)
const client = SmartyCore.buildClient.usStreet(credentials)

export type Result =
    | 'modified'
    | 'perfect_match'
    | 'required_secondary'
    | 'secondary_invalid'
    | 'non_residential'
    | 'not_found'
    | 'internal_error'

interface AddressResult {
    street: string
    secondary: string
    city: string
    state: string
    zip: string
}

interface ValidationResponse {
    candidate?: USStreet.Candidate
    dvpFootnotes: string[]
    footnotes: string[] | undefined
    result: Result[]
    addressSuggested?: AddressResult
}

/**
 * Validates an address and provides suggestions if invalid.
 * @param address The address to validate.
 * @returns A promise resolving to an array of suggested addresses.
 */
export async function validateAddress(address: AddressInput): Promise<ValidationResponse> {
    const lookup = new USStreet.Lookup()
    lookup.street = address.street
    lookup.secondary = address.secondary ?? ''
    lookup.city = address.city ?? ''
    lookup.state = address.state ?? ''
    lookup.zipCode = address.zipcode ?? ''
    lookup.match = 'invalid' // Use "invalid" to get suggestions if no exact match

    try {
        const response = await client.send(lookup)
        const { lookups } = response
        const firstLookup = lookups[0]
        const { result } = firstLookup

        const validationResult: Result[] = []

        // Since we are only giving one suggestion per try
        // Might as well be the first one
        const candidate = result[0]
        const { analysis, metadata } = candidate

        const deliveryPointValidation = interpretValidationResult(analysis.dpvFootnotes)
        const footnotes = analysis?.footnotes?.split('#').filter(Boolean)

        if (
            footnotes?.some(
                (n) =>
                    n.startsWith('A') ||
                    n.startsWith('B') ||
                    n.startsWith('C') ||
                    n.startsWith('G') ||
                    n.startsWith('K') ||
                    n.startsWith('L') ||
                    n.startsWith('M') ||
                    n.startsWith('N') ||
                    n.startsWith('O') ||
                    n.startsWith('P') ||
                    n.startsWith('U')
            ) ||
            deliveryPointValidation?.some((d) => d.startsWith('TA'))
        ) {
            validationResult.push('modified')
        }

        if (
            footnotes?.some(
                (n) =>
                    n.startsWith('D') ||
                    n.startsWith('F') ||
                    n.startsWith('I') ||
                    n.startsWith('R') ||
                    n.startsWith('V')
            ) ||
            deliveryPointValidation?.some((d) => d.startsWith('A1') || d.startsWith('M1') || d.startsWith('M3'))
        ) {
            validationResult.push('not_found')
        }

        if (
            footnotes?.some((n) => n.startsWith('H')) ||
            deliveryPointValidation?.some((d) => d.startsWith('C1') || d.startsWith('N1'))
        ) {
            validationResult.push('required_secondary')
        }
        if (
            footnotes?.some((n) => n.startsWith('S')) ||
            deliveryPointValidation?.some((d) => d.startsWith('CC') || d.startsWith('C1'))
        ) {
            validationResult.push('secondary_invalid')
        }

        if (footnotes?.some((n) => n.startsWith('J') || n.startsWith('T') || n.startsWith('W'))) {
            validationResult.push('internal_error')
        }
        if (
            deliveryPointValidation?.length === 2 &&
            deliveryPointValidation?.some((d) => d.startsWith('AA')) &&
            deliveryPointValidation?.some((d) => d.startsWith('BB')) &&
            analysis.dpvMatchCode === 'Y'
        ) {
            validationResult.push('perfect_match')
        }

        if (metadata.rdi === 'Commercial') {
            validationResult.push('non_residential')
        }

        // the address doesn't exist
        if (validationResult.includes('not_found') || validationResult.includes('internal_error')) {
            return {
                candidate: result[0],
                dvpFootnotes: deliveryPointValidation,
                footnotes,
                result: validationResult,
                addressSuggested: undefined,
            }
        }

        // secondary was given but it's not required
        if (validationResult.includes('secondary_invalid') && !validationResult.includes('required_secondary')) {
            return {
                candidate: result[0],
                dvpFootnotes: deliveryPointValidation,
                footnotes,
                result: validationResult,
                // igore the secondary
                addressSuggested: {
                    street: formatStreetAddress(candidate.components),
                    secondary: '',
                    city: candidate.components.cityName,
                    state: candidate.components.state,
                    zip: candidate.components.zipCode,
                },
            }
        }

        // the address is residential
        if (validationResult.includes('non_residential')) {
            return {
                candidate: result[0],
                dvpFootnotes: deliveryPointValidation,
                footnotes,
                result: validationResult,
                addressSuggested: undefined,
            }
        }

        return {
            candidate: result[0],
            dvpFootnotes: deliveryPointValidation,
            footnotes,
            result: validationResult,
            addressSuggested: {
                street: formatStreetAddress(candidate.components),
                secondary: formatApartmentAddress(candidate.components) ?? '',
                city: candidate.components.cityName,
                state: candidate.components.state,
                zip: candidate.components.zipCode,
            },
        }
    } catch (error) {
        return {
            candidate: undefined,
            dvpFootnotes: [],
            footnotes: undefined,
            result: [],
        }
    }
}
