/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { forwardRef, HTMLAttributes } from 'react'

interface Highlight {
    regex: RegExp // Regular expression to match the text to highlight
    highlightClassName?: string // Custom class for the matched text
    includeDelimiters?: boolean // Whether to include the delimiters in the highlighted text
}

interface Props extends HTMLAttributes<HTMLSpanElement> {
    text: string // Full text to render
    highlights: Highlight[] // Array of highlight configurations
    defaultClassName?: string // Class for non-highlighted text
}

const HighlightText = forwardRef<HTMLSpanElement, Props>(
    ({ text, highlights, defaultClassName, className, ...props }, ref) => {
        // Function to process the text with multiple highlights
        const processText = (inputText: string): React.ReactNode[] => {
            let parts: React.ReactNode[] = [inputText]

            highlights.forEach(({ regex, highlightClassName, includeDelimiters }) => {
                const newParts: React.ReactNode[] = []
                parts.forEach((part) => {
                    if (typeof part !== 'string') {
                        // If already processed, skip
                        newParts.push(part)
                        return
                    }
                    let lastIndex = 0
                    part.replace(regex, (match, ...args) => {
                        const matchIndex = args[args.length - 2] // Match index
                        if (lastIndex < matchIndex) {
                            newParts.push(part.slice(lastIndex, matchIndex)) // Non-matched text
                        }
                        const highlighted = includeDelimiters ? match : match.slice(1, -1)
                        newParts.push(
                            <span key={`${match}-${matchIndex}`} className={highlightClassName} {...props}>
                                {highlighted}
                            </span>
                        )
                        lastIndex = matchIndex + match.length
                        return match
                    })
                    if (lastIndex < part.length) {
                        newParts.push(part.slice(lastIndex)) // Remaining non-matched text
                    }
                })
                parts = newParts
            })

            return parts
        }

        return (
            <span ref={ref} className={`${defaultClassName} ${className}`}>
                {processText(text)}
            </span>
        )
    }
)

HighlightText.displayName = 'HighlightText'

export default HighlightText
