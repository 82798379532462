/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, HTMLAttributes, useContext } from 'react'
import { HiXMark } from 'react-icons/hi2'
import { StylesContext } from '../contexts/Styles'

interface Props extends HTMLAttributes<HTMLDivElement> {
    changeShowNotification: (value: boolean) => void
}

const NotificationPill = forwardRef<HTMLDivElement, Props>(
    ({ className, children, changeShowNotification, ...props }, ref) => {
        const [styles]: any = useContext(StylesContext)
        return (
            <div
                {...props}
                ref={ref}
                className={styles.twMerge(
                    'flex pl-6 pr-4 py-4 items-center justify-center bg-yellow-100 rounded-2xl border text-yellow-950 border-yellow-400 shadow-md',
                    className
                )}
            >
                {children}
                <button type="button" className="flex-1" onClick={() => changeShowNotification(false)}>
                    <HiXMark className="text-[#CA8A04] size-7" />
                </button>
            </div>
        )
    }
)

NotificationPill.displayName = 'NotificationPill'

export default NotificationPill
