// Packages
import React, { ReactNode, useEffect, useState } from 'react'
import reactStringReplace from 'react-string-replace'
// UI
import Link from '../ui/Link'

function StyleLink(link: any, linkText: any, onClick?: () => void) {
    return (
        <Link onClick={onClick} key={Math.random() * (1000 - 0) + 0} href={link}>
            {linkText}
        </Link>
    )
}

function DoubleBreak() {
    return (
        <>
            <br />
            <br />
        </>
    )
}

function SingleBreak() {
    return <br />
}

function StyleCopy({
    children,
    id,
    className,
    onLinkClick,
}: {
    children: string | ReactNode[] | undefined
    id?: string
    className?: string
    onLinkClick?: () => void
}) {
    const [BodyText2, setBodyText2]: any = useState(undefined)
    const styleIdentifiers = [
        {
            pattern: /<link>(.*?)<\/link>/gm,
            functionToRunOnMatched: (match: string) => {
                const linkAndLinkText = match.split('|')

                const link =
                    linkAndLinkText[1].startsWith('https://') ||
                    linkAndLinkText[1].startsWith('tel:') ||
                    linkAndLinkText[1].startsWith('popup:')
                        ? linkAndLinkText[1]
                        : `https://${linkAndLinkText[1]}`
                const linkText = linkAndLinkText[0]

                return StyleLink(link, linkText, onLinkClick)
            },
        },
        {
            pattern: /<dbr>(.*?)<\/dbr>/gm,
            functionToRunOnMatched: DoubleBreak,
        },
        {
            pattern: /<br>(.*?)<\/br>/gm,
            functionToRunOnMatched: SingleBreak,
        },
    ]

    useEffect(() => {
        let tempBodyText2 = children

        for (let i = 0; i < styleIdentifiers.length; i += 1) {
            tempBodyText2 = reactStringReplace(
                tempBodyText2,
                styleIdentifiers[i].pattern,
                styleIdentifiers[i].functionToRunOnMatched
            )
        }

        setBodyText2(tempBodyText2)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children])

    return (
        <p className={className} id={id}>
            {BodyText2}
        </p>
    )
}

StyleCopy.defaultProps = {
    id: undefined,
    className: '',
    onLinkClick: undefined,
}

export default StyleCopy
