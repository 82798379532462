/* eslint-disable no-nested-ternary */
// Packages
import React, { useContext } from 'react'
// Components
import ConditionalRender from './ConditionalRender'
import StyleCopy from './StyleCopy'
// UI
import Popup from '../ui/Popup'
import Heading from '../ui/Heading'
import SubHeading from '../ui/SubHeading'
import Button from '../ui/Button'
import Scroll from '../ui/Scroll'
import BodyText5 from '../ui/BodyText5'
import ToggleCard from '../ui/ToggleCard'
// Contexts
import { PopupContext } from '../contexts/Popup'
import { CopyContext } from '../contexts/Copy'
import { FormDataContext } from '../contexts/FormData'
import { ConfigContext } from '../contexts/Config'
import { StylesContext } from '../contexts/Styles'
import { CnxFifoQueueContext } from '../contexts/CnxFifoQueue'
// Helpers
import { parseCopy } from '../helpers/parseCopy'
import makeBackendRequest from '../helpers/backend'
import BodyText2 from '../ui/BodyText2'
import ScrollV2 from '../ui/ScrollV2'
import TitleText2 from '../ui/TitleText2'
import getStringDiff from '../helpers/getDiffString'
import HighlightText from '../ui/HighlightText'

function DisplayPopupBlocker() {
    // Contexts
    const [popup, popupDispatch]: any = useContext(PopupContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const [, cnxFifoQueue]: any = useContext(CnxFifoQueueContext)

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <ConditionalRender condition={popup?.componentToRender}>
            <ConditionalRender condition={typeof popup?.componentToRender !== 'string'}>
                <Popup>{popup?.componentToRender}</Popup>
            </ConditionalRender>
            <ConditionalRender condition={typeof popup?.componentToRender === 'string'}>
                <ConditionalRender condition={copy?.popups?.[popup.componentToRender]?.type === 'basic'}>
                    <Popup>
                        <Heading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.heading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.heading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.heading?.desktop
                            )}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                        </Heading>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.divider?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.divider?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.divider?.desktop
                            )}
                        />
                        <Scroll>
                            <SubHeading
                                className={styles.twMerge(
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.mobile,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.tablet,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.desktop
                                )}
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.popups?.[popup.componentToRender]?.components?.body, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            </SubHeading>
                        </Scroll>
                        <Button
                            type="ghost"
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.button?.desktop
                            )}
                            onClick={() => {
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.closeButton}</StyleCopy>
                        </Button>
                    </Popup>
                </ConditionalRender>
                <ConditionalRender condition={copy?.popups?.[popup.componentToRender]?.type === 'legal'}>
                    <Popup>
                        <Heading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.heading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.heading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.heading?.desktop
                            )}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                        </Heading>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.divider?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.divider?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.divider?.desktop
                            )}
                        />
                        <Scroll>
                            <SubHeading
                                className={styles.twMerge(
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.mobile,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.tablet,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.desktop
                                )}
                            >
                                <BodyText5>
                                    <StyleCopy>
                                        {parseCopy(copy?.popups?.[popup.componentToRender]?.components?.body, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                </BodyText5>
                            </SubHeading>
                        </Scroll>
                        <Button
                            type="ghost"
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.button?.desktop
                            )}
                            onClick={() => {
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.closeButton}</StyleCopy>
                        </Button>
                    </Popup>
                </ConditionalRender>
                <ConditionalRender condition={copy?.popups?.[popup.componentToRender]?.type === 'fundleBundle'}>
                    <Popup>
                        <Heading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.heading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.heading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.heading?.desktop
                            )}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                        </Heading>
                        <SubHeading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.subheading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.subheading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.subheading?.desktop
                            )}
                        >
                            <BodyText2>
                                <StyleCopy>
                                    {parseCopy(copy?.popups?.[popup.componentToRender]?.components?.body, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            </BodyText2>
                        </SubHeading>
                        <div>
                            {copy?.popups?.[popup.componentToRender]?.components?.bundles?.map((bundle: any) => {
                                return (
                                    <ToggleCard
                                        key={bundle.name}
                                        description={bundle.description}
                                        toggleLeft
                                        imageString={bundle.image}
                                        onCheck={() => {
                                            // Save the fundle bundle selected in formData
                                            formDataDispatch({
                                                type: 'SET_FIELD',
                                                payload: {
                                                    name: `fundleBundle${bundle.name}`,
                                                    value: !formData[`fundleBundle${bundle.name}`],
                                                },
                                            })
                                        }}
                                    />
                                )
                            })}
                        </div>
                        <Button
                            type="fill"
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.button?.desktop
                            )}
                            onClick={() => {
                                // Make an API call to cnx
                                makeBackendRequest(
                                    { stack: 'connexOne', endpoint: 'processDemoform' },
                                    { formData, config, cnxFifoQueue }
                                )
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.closeButton}</StyleCopy>
                        </Button>
                    </Popup>
                </ConditionalRender>
                <ConditionalRender condition={copy?.popups?.[popup.componentToRender]?.type === 'address'}>
                    <Popup className="flex w-full flex-col px-5 pb-8 h-auto max-h-[90vh]">
                        <div>
                            <Heading
                                className={styles.twMerge(
                                    styles?.ui?.DisplayPopupBlocker?.heading?.mobile,
                                    styles?.ui?.DisplayPopupBlocker?.heading?.tablet,
                                    styles?.ui?.DisplayPopupBlocker?.heading?.desktop
                                )}
                            >
                                <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                            </Heading>
                            <div
                                className={styles.twMerge(
                                    styles?.ui?.DisplayPopupBlocker?.divider?.mobile,
                                    styles?.ui?.DisplayPopupBlocker?.divider?.tablet,
                                    styles?.ui?.DisplayPopupBlocker?.divider?.desktop,
                                    'md:-mx-5'
                                )}
                            />
                        </div>
                        <ScrollV2>
                            <SubHeading
                                className={styles.twMerge(
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.mobile,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.tablet,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.desktop
                                )}
                            >
                                <HighlightText
                                    text={copy?.popups?.[popup.componentToRender]?.components?.body}
                                    highlights={[
                                        {
                                            regex: /(\[.*?\])/g, // antyhing between brackets
                                            highlightClassName: styles?.ui?.DisplayPopupBlocker?.textHighlight,
                                            includeDelimiters: true,
                                        },
                                    ]}
                                />
                            </SubHeading>

                            <ConditionalRender
                                condition={
                                    popup.input && copy?.popups?.[popup.componentToRender]?.components?.cards?.input
                                }
                            >
                                <div className={styles?.ui?.DisplayPopupBlocker?.input?.outterContainer}>
                                    <TitleText2 className={styles?.ui?.DisplayPopupBlocker?.input?.name}>
                                        {copy?.popups?.[popup.componentToRender]?.components?.cards?.input?.name}
                                    </TitleText2>
                                    <div
                                        className={styles.twMerge(
                                            styles?.ui?.DisplayPopupBlocker?.input?.container?.mobile,
                                            styles?.ui?.DisplayPopupBlocker?.input?.container?.tablet,
                                            styles?.ui?.DisplayPopupBlocker?.input?.container?.desktop
                                        )}
                                    >
                                        <div
                                            className={styles.twMerge(
                                                styles?.ui?.DisplayPopupBlocker?.input?.entries?.mobile,
                                                styles?.ui?.DisplayPopupBlocker?.input?.entries?.tablet,
                                                styles?.ui?.DisplayPopupBlocker?.input?.entries?.desktop
                                            )}
                                        >
                                            <BodyText2>
                                                <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                    {
                                                        copy?.popups?.[popup.componentToRender]?.components?.cards
                                                            ?.input?.address
                                                    }
                                                </span>{' '}
                                                {popup.input?.street}
                                                {popup.input?.secondary ? ', ' : ' '}
                                                {popup.input?.secondary}
                                            </BodyText2>

                                            <ConditionalRender
                                                condition={
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards
                                                        ?.suggestion
                                                }
                                            >
                                                <button
                                                    className={styles?.ui?.DisplayPopupBlocker?.input?.editButton}
                                                    type="button"
                                                    onClick={() => popupDispatch({ type: 'REMOVE_POPUP' })}
                                                >
                                                    <TitleText2
                                                        className={styles?.ui?.DisplayPopupBlocker?.textUnderline}
                                                    >
                                                        {
                                                            copy?.popups?.[popup.componentToRender]?.components?.cards
                                                                ?.input?.edit
                                                        }
                                                    </TitleText2>
                                                </button>
                                            </ConditionalRender>
                                        </div>
                                        <BodyText2>
                                            <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                {
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards?.input
                                                        ?.city
                                                }
                                            </span>{' '}
                                            {popup.input?.city}
                                        </BodyText2>
                                        <BodyText2>
                                            <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                {
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards?.input
                                                        ?.state
                                                }
                                            </span>{' '}
                                            {popup.input?.state}
                                        </BodyText2>
                                        <BodyText2>
                                            <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                {
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards?.input
                                                        ?.zipCode
                                                }
                                            </span>{' '}
                                            {popup.input?.zip}
                                        </BodyText2>
                                    </div>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender
                                condition={
                                    popup.addressAutocomplete &&
                                    copy?.popups?.[popup.componentToRender]?.components?.cards?.input
                                }
                            >
                                <div className={styles?.ui?.DisplayPopupBlocker?.input?.outterContainer}>
                                    <div
                                        className={styles.twMerge(
                                            styles?.ui?.DisplayPopupBlocker?.input?.container?.mobile,
                                            styles?.ui?.DisplayPopupBlocker?.input?.container?.tablet,
                                            styles?.ui?.DisplayPopupBlocker?.input?.container?.desktop
                                        )}
                                    >
                                        <div
                                            className={styles.twMerge(
                                                styles?.ui?.DisplayPopupBlocker?.input?.entries?.mobile,
                                                styles?.ui?.DisplayPopupBlocker?.input?.entries?.tablet,
                                                styles?.ui?.DisplayPopupBlocker?.input?.entries?.desktop,
                                                'py-3'
                                            )}
                                        >
                                            <BodyText2>
                                                <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                    {
                                                        copy?.popups?.[popup.componentToRender]?.components?.cards
                                                            ?.input?.address
                                                    }
                                                </span>{' '}
                                                {popup.addressSingleLine}
                                            </BodyText2>
                                            <ConditionalRender
                                                condition={
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards
                                                        ?.suggestion
                                                }
                                            >
                                                <button
                                                    className={styles?.ui?.DisplayPopupBlocker?.input?.editButton}
                                                    type="button"
                                                    onClick={() => popupDispatch({ type: 'REMOVE_POPUP' })}
                                                >
                                                    <TitleText2
                                                        className={styles?.ui?.DisplayPopupBlocker?.textUnderline}
                                                    >
                                                        {
                                                            copy?.popups?.[popup.componentToRender]?.components?.cards
                                                                ?.input?.edit
                                                        }
                                                    </TitleText2>
                                                </button>
                                            </ConditionalRender>
                                        </div>
                                    </div>
                                </div>
                            </ConditionalRender>
                            <ConditionalRender
                                condition={copy?.popups?.[popup.componentToRender]?.components?.cards?.suggestion}
                            >
                                <div className={(styles?.ui?.DisplayPopupBlocker?.suggestion?.outterContainer, 'my-3')}>
                                    <TitleText2 className={styles?.ui?.DisplayPopupBlocker?.suggestion?.name}>
                                        {copy?.popups?.[popup.componentToRender]?.components?.cards?.suggestion?.name}
                                    </TitleText2>
                                    <div
                                        className={styles.twMerge(
                                            styles?.ui?.DisplayPopupBlocker?.suggestion?.container?.mobile,
                                            styles?.ui?.DisplayPopupBlocker?.suggestion?.container?.tablet,
                                            styles?.ui?.DisplayPopupBlocker?.suggestion?.container?.desktop
                                        )}
                                    >
                                        <BodyText2>
                                            <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                {
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards
                                                        ?.suggestion?.address
                                                }
                                            </span>{' '}
                                            {!popup.input ? (
                                                popup.suggestion?.street
                                            ) : (
                                                <HighlightText
                                                    text={getStringDiff({
                                                        expected: popup.suggestion?.street ?? '',
                                                        given: popup.input?.street ?? '',
                                                    })}
                                                    highlights={[
                                                        {
                                                            regex: /(\[.*?\])/g, // antyhing between brackets
                                                            highlightClassName:
                                                                styles?.ui?.DisplayPopupBlocker?.textHighlight,
                                                            includeDelimiters: true,
                                                        },
                                                    ]}
                                                />
                                            )}
                                            {/* Check if there's a secondary address in the input */}
                                            {/* and if the secondary is really necessary */}
                                            {popup.suggestion?.secondary ? ', ' : ' '}
                                            {!popup.input ? (
                                                popup.suggestion?.secondary
                                            ) : (
                                                <HighlightText
                                                    text={getStringDiff({
                                                        expected: popup.suggestion?.secondary ?? '',
                                                        given: popup.input?.secondary ?? '',
                                                    })}
                                                    highlights={[
                                                        {
                                                            regex: /(\[.*?\])/g, // antyhing between brackets
                                                            highlightClassName:
                                                                styles?.ui?.DisplayPopupBlocker?.textHighlight,
                                                            includeDelimiters: true,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </BodyText2>
                                        <BodyText2>
                                            <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                {
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards
                                                        ?.suggestion?.city
                                                }
                                            </span>{' '}
                                            {!popup.input ? (
                                                popup.suggestion?.city
                                            ) : (
                                                <HighlightText
                                                    text={getStringDiff({
                                                        expected: popup.suggestion?.city ?? '',
                                                        given: popup.input?.city ?? '',
                                                    })}
                                                    highlights={[
                                                        {
                                                            regex: /(\[.*?\])/g, // antyhing between brackets
                                                            highlightClassName:
                                                                styles?.ui?.DisplayPopupBlocker?.textHighlight,
                                                            includeDelimiters: true,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </BodyText2>
                                        <BodyText2>
                                            <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                {
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards
                                                        ?.suggestion?.state
                                                }
                                            </span>{' '}
                                            {!popup.input ? (
                                                popup.suggestion?.state
                                            ) : (
                                                <HighlightText
                                                    text={getStringDiff({
                                                        expected: popup.suggestion?.state ?? '',
                                                        given: popup.input?.state ?? '',
                                                    })}
                                                    highlights={[
                                                        {
                                                            regex: /(\[.*?\])/g, // antyhing between brackets
                                                            highlightClassName:
                                                                styles?.ui?.DisplayPopupBlocker?.textHighlight,
                                                            includeDelimiters: true,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </BodyText2>
                                        <BodyText2>
                                            <span className={styles?.ui?.DisplayPopupBlocker?.entryLabel}>
                                                {
                                                    copy?.popups?.[popup.componentToRender]?.components?.cards
                                                        ?.suggestion?.zipCode
                                                }
                                            </span>{' '}
                                            {!popup.input ? (
                                                popup.suggestion?.zip
                                            ) : (
                                                <HighlightText
                                                    text={getStringDiff({
                                                        expected: popup.suggestion?.zip ?? '',
                                                        given: popup.input?.zip ?? '',
                                                    })}
                                                    highlights={[
                                                        {
                                                            regex: /(\[.*?\])/g, // antyhing between brackets
                                                            highlightClassName:
                                                                styles?.ui?.DisplayPopupBlocker?.textHighlight,
                                                            includeDelimiters: true,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </BodyText2>
                                    </div>
                                </div>
                            </ConditionalRender>
                        </ScrollV2>
                        <div className="px-3">
                            <ConditionalRender
                                condition={copy?.popups?.[popup.componentToRender]?.components?.cards?.suggestion}
                                falseReturn={
                                    <Button
                                        type="ghost"
                                        className={styles.twMerge(
                                            styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                            styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                            styles?.ui?.DisplayPopupBlocker?.button?.desktop,
                                            'px-3 mt-4'
                                        )}
                                        onClick={() => {
                                            popupDispatch({ type: 'REMOVE_POPUP' })
                                        }}
                                    >
                                        <StyleCopy>
                                            {copy?.popups?.[popup.componentToRender]?.components?.closeButton}
                                        </StyleCopy>
                                    </Button>
                                }
                            >
                                <div>
                                    <Button
                                        type="fill"
                                        className={styles.twMerge(
                                            styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                            styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                            styles?.ui?.DisplayPopupBlocker?.button?.desktop,
                                            'px-2 mt-4'
                                        )}
                                        onClick={async () => {
                                            popupDispatch({ type: 'REMOVE_POPUP' })
                                            await popup.onNext()
                                        }}
                                    >
                                        <StyleCopy>
                                            {copy?.popups?.[popup.componentToRender]?.components?.closeButton}
                                        </StyleCopy>
                                    </Button>

                                    <ConditionalRender
                                        condition={copy?.popups?.[popup.componentToRender]?.components?.secondaryButton}
                                    >
                                        <Button
                                            type="ghost"
                                            className={styles.twMerge(
                                                styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                                styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                                styles?.ui?.DisplayPopupBlocker?.button?.desktop,
                                                'px-3 mt-2'
                                            )}
                                            onClick={() => {
                                                popupDispatch({ type: 'REMOVE_POPUP' })
                                            }}
                                        >
                                            <StyleCopy>
                                                {copy?.popups?.[popup.componentToRender]?.components?.secondaryButton}
                                            </StyleCopy>
                                        </Button>
                                    </ConditionalRender>
                                </div>
                            </ConditionalRender>
                        </div>
                    </Popup>
                </ConditionalRender>
            </ConditionalRender>
        </ConditionalRender>
    )
}

export default DisplayPopupBlocker
