import moment from 'moment'
import makeBackendRequest from '../backend/index'

const requireValue = (condition: boolean | string, message: string, metaData = {}) => {
    if (condition) {
        return { isValid: true, message: '' }
    }

    return { isValid: false, message, metaData }
}
const negativeRequireValue = (condition: boolean | string, message: string, metaData = {}) => {
    if (condition) {
        return { isValid: false, message, metaData }
    }

    return { isValid: true, message: '' }
}

export const requireOnlyValidNladCharacters = async (value: string) => {
    return requireValue(/(^[a-zA-ZàèìǹòùẁÀÈÌÒǸÙẀ '-]+$|^$)/u.test(value), 'nladCharacters')
}

export const requireOnlyNumbers = async (value: string) => {
    if (value) {
        return requireValue(/^\d*$/.test(value), 'onlyNumbers')
    }

    return requireValue(true, '')
}

export const requireOnlyNumbersAndDash = async (value: string) => {
    return requireValue(/^[0-9-]+$/.test(value), 'onlyNumbersOrDashes')
}

export const requireNonEmptyValue = async (value: string) => {
    return requireValue(value, 'required')
}

export const requireXCharacters = async (value: string, length: number, units = 'digits') => {
    if (value?.length) {
        return requireValue(value.length === length, 'xCharacters', { length, units })
    }

    return requireValue(true, '')
}

export const requireMoreThanXCharacters = async (value: string, length: number, units = 'digits') => {
    if (value?.length) {
        return requireValue(value.length >= length, 'moreThanXCharacters', { length, units })
    }

    return requireValue(true, '')
}

export const requireGreatThanX = async (value: number, x: number) => {
    return requireValue(!(value > x), 'greaterThanX', { x })
}

export const requireValidEmail = async (value: string) => {
    if (value) {
        return requireValue(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
            ),
            'validEmail'
        )
    }

    return requireValue(true, '')
}

export const requireNotPoBox = (smartyResponse: any, formKeys: any) => {
    return {
        ...negativeRequireValue(smartyResponse?.data?.metadata?.record_type === 'P', 'notPoBox'),
        erroredName: formKeys.street,
    }
}
export const requireValidAddress = (smartyResponse: any, formKeys: any) => {
    return {
        ...negativeRequireValue(!smartyResponse?.data?.metadata?.record_type, 'validAddress'),
        erroredName: formKeys.street,
    }
}

// Added to support different messages for samer error
// Start
export const requireNonEmptyCity = async (value: string) => {
    return requireValue(value, 'requiredCity')
}

export const requireValidAddressAutoComplete = (smartyResponse: any, formKeys: any) => {
    return {
        ...negativeRequireValue(!smartyResponse?.data?.metadata?.record_type, 'validAddressAutocomplete'),
        erroredName: formKeys.street,
    }
}
export const requireValidAddressManual = (smartyResponse: any, formKeys: any) => {
    return {
        ...negativeRequireValue(!smartyResponse?.data?.metadata?.record_type, 'validAddressManual'),
        erroredName: formKeys.street,
    }
}
export const requireNonEmptyStreetAutoComplete = async (value: string) => {
    return requireValue(value, 'requiredStreetAutocomplete')
}
export const requireNonEmptyStreetManual = async (value: string) => {
    return requireValue(value, 'requiredStreetManual')
}
// End

export const requireResidentialAddress = (smartyResponse: any, formKeys: any) => {
    return {
        ...negativeRequireValue(smartyResponse?.data?.metadata?.rdi !== 'Residential', 'requireResidential'),
        erroredName: formKeys.street,
    }
}
export const requireAddressLine2IfAddressHasUnits = (smartyResponse: any, formKeys: any) => {
    return {
        ...negativeRequireValue(
            smartyResponse?.data?.analysis?.enhanced_match?.includes('missing-secondary'),
            'requireAddressLine2'
        ),
        erroredName: formKeys.secondary,
    }
}
export const requireValidAddressLine2 = (smartyResponse: any, formKeys: any) => {
    return {
        ...negativeRequireValue(
            smartyResponse?.data?.analysis?.enhanced_match?.includes('unknown-secondary') ||
                smartyResponse?.data?.analysis?.dpv_footnotes === 'AABBCC' ||
                (smartyResponse?.data?.metadata?.record_type === 'H' &&
                    !smartyResponse?.data?.components?.secondary_designator &&
                    !smartyResponse?.data?.components?.secondary_number),
            'validAddressLine2'
        ),
        erroredName: formKeys.secondary,
    }
}

export const smartyValidation = async (
    endpoint: any,
    functions: Function[],
    parameters: { formData: any; formDataKeys: any; formDataDispatch: any }
) => {
    let smartyObject = {
        data: {
            msg: '',
            formattedAddress: 'No Result',
            components: {},
            analysis: {},
        },
    }
    if (parameters?.formData?.[parameters?.formDataKeys?.street]) {
        const smartyResponse = await makeBackendRequest(
            { stack: 'smartyValidation', endpoint: 'main' },
            { ...endpoint.request, formData: parameters.formData, valueKeys: parameters.formDataKeys }
        )
        smartyObject = await smartyResponse.json()
        if (parameters.formDataDispatch) {
            parameters.formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: `${parameters?.formDataKeys[Object.keys(parameters?.formDataKeys)?.[0]]}SmartyResponse`,
                    value: smartyObject,
                },
            })
        }
    }

    let isValid = false
    let message = ''
    let metaData = {}
    let erroredName = ''

    for (let i = 0; i < functions.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const functionResponse = await functions[i](smartyObject, parameters.formDataKeys)

        isValid = functionResponse.isValid
        message = functionResponse.message
        metaData = functionResponse.metaData
        erroredName = functionResponse.erroredName

        if (!functionResponse.isValid) {
            break
        }
    }

    return { isValid, message, metaData, erroredName }
}

export const addressValidationCodes: Record<string, string> = {
    AA: 'Street name, city, state, and ZIP are all valid.',
    A1: 'Address not present in USPS data.',
    BB: 'Entire address is valid.',
    CC: 'Secondary information not recognized. Secondary number NOT REQUIRED for delivery.',
    C1: 'Secondary information not recognized. Secondary number IS REQUIRED for delivery.',
    F1: 'Military or diplomatic address.',
    G1: 'General delivery address.',
    M1: 'Primary number (e.g., house number) is missing.',
    M3: 'Primary number (e.g., house number) is invalid.',
    N1: 'Address is missing secondary information required for delivery.',
    PB: 'PO Box street style address.',
    P1: 'PO, RR, or HC box number is missing.',
    P3: 'PO, RR, or HC box number is invalid.',
    RR: 'Confirmed address with private mailbox (PMB) info.',
    R1: 'Confirmed address without private mailbox (PMB) info.',
    R7: "Valid address that doesn't currently receive USPS street delivery.",
    TA: 'Primary number matched by dropping trailing alpha.',
    U1: "Address has a 'unique' ZIP Code.",
}

export function interpretValidationResult(code: string): string[] {
    const descriptions: string[] = []

    // Split the code into chunks of two characters
    for (let i = 0; i < code.length; i += 2) {
        const part = code.slice(i, i + 2)
        if (addressValidationCodes[part]) {
            descriptions.push(`${part}: ${addressValidationCodes[part]}`)
        } else {
            descriptions.push(`${part}: Unknown code.`)
        }
    }

    return descriptions
}
// this function will set the addressStreetSmartyResponse in the formData object
export const smartyValidationV2 = async (
    endpoint: any,
    functions: Function[],
    parameters: { formData: any; formDataKeys: any; formDataDispatch: any }
) => {
    let smartyObject = {
        data: {
            msg: '',
            formattedAddress: 'No Result',
            components: {},
            analysis: {},
        },
    }
    if (parameters?.formData?.[parameters?.formDataKeys?.street]) {
        const smartyResponse = await makeBackendRequest(
            { stack: 'smartyValidation', endpoint: 'main' },
            { ...endpoint.request, formData: parameters.formData, valueKeys: parameters.formDataKeys }
        )
        smartyObject = await smartyResponse.json()
        if (parameters.formDataDispatch) {
            parameters.formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: `${parameters?.formDataKeys[Object.keys(parameters?.formDataKeys)?.[0]]}SmartyResponse`,
                    value: smartyObject,
                },
            })
        }
    }

    let isValid = false
    let message = ''
    let metaData = {}
    let erroredName = ''

    for (let i = 0; i < functions.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const functionResponse = await functions[i](smartyObject, parameters.formDataKeys)
        const dpvFootnotes = (smartyObject?.data?.analysis as { dpv_footnotes: string })?.dpv_footnotes
        const containsUnnecesarySecondary = interpretValidationResult(dpvFootnotes ?? '').some((dvp) =>
            dvp.startsWith('CC')
        )

        // If the user inputs a secondary address or Smarty thinks they meant to add one and...
        // The submitted secondary information (apartment, suite, etc.) was not recognized.
        // Secondary number is NOT REQUIRED for delivery.
        isValid =
            functionResponse.message === 'validAddressLine2' && containsUnnecesarySecondary
                ? true
                : functionResponse.isValid
        message = functionResponse.message
        metaData = functionResponse.metaData
        erroredName = functionResponse.erroredName

        if (!functionResponse.isValid) {
            break
        }
    }

    return { isValid, message, metaData, erroredName }
}

export const requireNotToBeAllSpaces = (value: string) => {
    return requireValue(!/^\s+$/.test(value), 'onlySpaces')
}

export const requireCompleteDate = (value: string) => {
    return requireValue(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.test(value), 'requireCompleteDate')
}

export const requireAgeToBeGreaterThan18 = (value: string) => {
    const currentDate = moment()
    const valueMoment = moment(value)

    const differenceInYears = currentDate.diff(valueMoment, 'years')

    return requireValue(differenceInYears >= 18, 'requireOlderThan18')
}
