import { usStreet } from 'smartystreets-javascript-sdk'

export function formatStreetAddress(components: usStreet.Component | undefined): string {
    if (!components) return ''
    const { primaryNumber, streetPredirection, streetName, streetSuffix, streetPostdirection } = components

    const streetAddress = [primaryNumber, streetPredirection, streetName, streetSuffix, streetPostdirection]
        .filter(Boolean) // Remove undefined or null values
        .join(' ')

    return streetAddress
}

export function formatApartmentAddress(components: usStreet.Component | undefined): string | undefined {
    if (!components) return ''
    const { secondaryDesignator, secondaryNumber } = components

    if (secondaryDesignator && secondaryNumber) {
        return `${secondaryDesignator} ${secondaryNumber}`
    }

    return undefined // Return undefined if there's no apartment/unit info
}

export function formatFullAddress(
    components: usStreet.Component | undefined,
    {
        includePlus4 = false,
        includeSecondary = false,
        includeZip = true,
        separateState = true,
        separateStreet = false,
    }: {
        includePlus4?: boolean
        includeSecondary?: boolean
        includeZip?: boolean
        separateState?: boolean
        separateStreet?: boolean
    }
): string {
    if (!components) return ''
    const { cityName, state, zipCode, plus4Code } = components

    const street = formatStreetAddress(components)
    const apartment = includeSecondary ? formatApartmentAddress(components) : ''

    const cityStateZip = `${cityName}${separateState ? ',' : ''} ${state} ${includeZip ? zipCode : ''}${plus4Code && includePlus4 ? `-${plus4Code}` : ''}`

    return [street, separateStreet ? ',' : '', apartment, cityStateZip].filter(Boolean).join(' ')
}
