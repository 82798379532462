import { diffWords } from 'diff'

interface StringInput {
    given: string
    expected: string
}

interface Delimiters {
    start?: string
    end?: string
}

/**
 * Highlights the differences between two strings with a custom delimeter.
 * @param diffInput The strings to compare.
 * @param delimiters The delimiters to use.
 * @returns A string highlighting the differences between.
 */

function getStringDiff(input: StringInput, delimiters?: Delimiters): string {
    const { expected, given } = input
    const result = diffWords(given, expected)

    const highlighted = result
        .map((part) => {
            if (part.added) {
                return `${delimiters ? delimiters.start : '['}${part.value}${delimiters ? delimiters.end : ']'}` // Highlight additions
            }
            if (part.removed) {
                return '' // Ignore removals
            }
            return part.value // Keep unchanged text
        })
        .join('')
    return highlighted
}

export default getStringDiff
