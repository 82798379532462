/* eslint-disable no-unused-vars */
import SmartySDK from 'smartystreets-javascript-sdk'

// Smarty
const SmartyCore = SmartySDK.core
const { Lookup } = SmartySDK.usAutocompletePro
const key: string = process.env.REACT_APP_DIRECT_SMARTY_API_KEY ? process.env.REACT_APP_DIRECT_SMARTY_API_KEY : ''
const credentials = new SmartyCore.SharedCredentials(key)
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud'])
const client = clientBuilder.buildUsAutocompleteProClient()

interface AddressComponents {
    street: string
    secondary?: string
    entries?: number
    city: string
    state: string
    zip: string
}

type Suggestion = SmartySDK.usAutocompletePro.Suggestion

interface QueryBuilderComponents {
    input: {
        street: string
        secondary?: string
    }
    selected?: AddressComponents
}

/**
 * This function transfroms an object containing address components into a string.
 *
 * @param components An object containing the address components.
 * @returns a string represeting an address.
 */
export const addressToString = (components: AddressComponents) => {
    const street = components.street ? `${components.street} ` : ''
    const secondary = components?.secondary ? `${components.secondary} ` : ''
    const entries = components?.entries && components.entries > 0 ? `(${components.entries}) ` : ''
    const city = components?.city ? `${components.city} ` : ''
    const state = components?.state ? `${components.state}, ` : ''
    const zip = components?.zip ? `${components.zip}` : ''

    return street + secondary + entries + city + state + zip
}

/**
 * Takes in a user input and (optionally) a selected address and returns a list of suggestions from the Smarty US Autocomplete Pro API.
 *
 * If secondary address is given it will automatically create the ```selected```
 * property for the Smarty API to make the necesesary secondary number expansion logic
 *
 * ```ts
 * const suggestions = await getSmartySuggestion({
 *   input: {
 *      street: "123 Main St",
 *      secondary: "Apt"
 *   },
 *   selected: {
 *     ...components
 *   }
 * })
 * ```
 *
 * @param queryBuilder An object containing the input from the user and the (optional) selected address string as keys.
 * @returns An array suggestions. If an error occurs, it will return an empty array.
 *
 * This is an updated version of the original function. It introduces TypeScript for a better DX.
 * A second version was created instead of rewriting the original to avoid conflicts with other parts of the App that rely on it.
 */
export const getSmartySuggestion = async ({ input, selected }: QueryBuilderComponents): Promise<Suggestion[]> => {
    let search = ''

    // IMPORTANT: To get apt/units
    // The search must include a part of the secondary address in order to get suggestions
    // https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#:~:text=the%20selected%20address-,up%20to%20the%20left%20parenthesis,-%2C%20with%20no%20trailing
    if (input.secondary) {
        search = `${input.street} ${input.secondary}`
    } else {
        search = input.street
    }

    const lookup = new Lookup(search)
    lookup.maxResults = 5

    if (selected) {
        const parsedSelectedAddress = addressToString(selected)
        lookup.selected = parsedSelectedAddress
    }

    try {
        const { result } = await client.send(lookup)
        return result
    } catch (error) {
        return []
    }
}
