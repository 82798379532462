/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// Packages
import React, { useEffect, useState, useContext } from 'react'
import { GrDown, GrUp } from 'react-icons/gr'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { QueryParamsContext } from '../contexts/QueryParams'
import { ConfigContext } from '../contexts/Config'
// Components
import ConditionalRender from '../components/ConditionalRender'
// UI
import Button from './Button'
import BodyText2 from './BodyText2'
import Card from './Card'
import HeadingText3 from './HeadingText3'
import ShowMore from './ShowMore'
import TitleText2 from './TitleText2'
import TitleText1 from './TitleText1'
// Helpers
import getConfigData from '../helpers/config/getConfigData'
import TitleText3 from './TitleText3'
import BodyText4 from './BodyText4'

function OfferCard({
    className = '',
    priceText,
    buttonCopy,
    shortDescription,
    equipmentDescription,
    priceLockDescription,
    sections,
    showMoreCopy,
    showLessCopy,
    isAcp,
    isLifeline,
    strikedPriceText,
    giftCard,
    onClick,
    imageString,
    timeUnit,
    mobileGift,
    badge,
    topServiceableCompany,
    offerValue,
    offerTitleTop,
    offerTitleUnit,
    hboMaxOfferLength,
}: {
    [key: string]: any
}) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config]: any = useContext(ConfigContext)
    // States
    const [showMore, setShowMore]: any = useState(false)
    const [showMoreSections, setShowMoreSections]: any = useState({})
    const [image, setImage]: any = useState({})
    const [presentImage, setPresentImage]: any = useState({})
    const [phoneImage, setPhoneImage]: any = useState({})

    useEffect(() => {
        sections?.forEach((section: any, i: Number) => {
            setShowMoreSections((prev: any) => ({
                ...prev,
                [section?.title]: i === 0,
            }))
        })
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: imageString,
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'Present.png',
        }).then((data: { [key: string]: any }) => {
            setPresentImage(data)
        })
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'Phone.png',
        }).then((data: { [key: string]: any }) => {
            setPhoneImage(data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <Card
            className={styles.twMerge(
                styles?.ui?.OfferCard?.Card?.mobile,
                styles?.ui?.OfferCard?.Card?.tablet,
                styles?.ui?.OfferCard?.Card?.desktop,
                badge ? styles?.ui?.OfferCard?.Card?.Badge : '',
                className
            )}
        >
            <div
                className={styles.twMerge(
                    styles?.ui?.OfferCard?.Head?.mobile,
                    styles?.ui?.OfferCard?.Head?.tablet,
                    styles?.ui?.OfferCard?.Head?.desktop
                )}
            >
                <div
                    className={styles.twMerge(
                        styles?.ui?.OfferCard?.imgContainer?.mobile,
                        styles?.ui?.OfferCard?.imgContainer?.tablet,
                        styles?.ui?.OfferCard?.imgContainer?.desktop
                    )}
                >
                    <ConditionalRender
                        condition={offerTitleTop}
                        falseReturn={
                            <img
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.img?.mobile,
                                    styles?.ui?.OfferCard?.img?.tablet,
                                    styles?.ui?.OfferCard?.img?.desktop
                                )}
                                src={image?.url}
                                alt={image?.alt}
                            />
                        }
                    >
                        <div className="flex flex-col items-center justify-center w-full h-full">
                            <p className="text-4xl font-bold text-white">{offerTitleTop}</p>
                            <ConditionalRender condition={offerTitleUnit}>
                                <TitleText1 className="text-white">{offerTitleUnit}</TitleText1>
                            </ConditionalRender>
                            <p className="text-white">Home Internet</p>
                        </div>
                    </ConditionalRender>
                </div>
                <div
                    className={styles.twMerge(
                        styles?.ui?.OfferCard?.priceContainer?.mobile,
                        styles?.ui?.OfferCard?.priceContainer?.tablet,
                        styles?.ui?.OfferCard?.priceContainer?.desktop
                    )}
                >
                    <ConditionalRender condition={isAcp}>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.OfferCard?.strikedPrice?.mobile,
                                styles?.ui?.OfferCard?.strikedPrice?.tablet,
                                styles?.ui?.OfferCard?.strikedPrice?.desktop
                            )}
                        >
                            <BodyText2>{strikedPriceText}</BodyText2>
                        </div>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.OfferCard?.mainPriceContainer?.mobile,
                                styles?.ui?.OfferCard?.mainPriceContainer?.tablet,
                                styles?.ui?.OfferCard?.mainPriceContainer?.desktop
                            )}
                        >
                            <HeadingText3
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.price?.mobile,
                                    styles?.ui?.OfferCard?.price?.tablet,
                                    styles?.ui?.OfferCard?.price?.desktop
                                )}
                            >
                                {priceText}
                            </HeadingText3>
                            <div
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.timeUnit?.mobile,
                                    styles?.ui?.OfferCard?.timeUnit?.tablet,
                                    styles?.ui?.OfferCard?.timeUnit?.desktop
                                )}
                            >
                                <BodyText2>{timeUnit}</BodyText2>
                            </div>
                        </div>
                    </ConditionalRender>
                    <ConditionalRender condition={isLifeline}>
                        <div
                            className={
                                styles.twMerge(
                                    styles?.ui?.OfferCard?.mainPriceContainer?.mobile,
                                    styles?.ui?.OfferCard?.mainPriceContainer?.tablet,
                                    styles?.ui?.OfferCard?.mainPriceContainer?.desktop
                                ) + ' flex flex-col items-center'
                            }
                        >
                            <div className="mb-[-5px]">
                                <BodyText2 className="text-red-500 line-through">
                                    <BodyText2 className="text-black">{priceText}</BodyText2>
                                </BodyText2>
                            </div>
                            <div className="flex flex-row">
                                <HeadingText3
                                    className={styles.twMerge(
                                        styles?.ui?.OfferCard?.price?.mobile,
                                        styles?.ui?.OfferCard?.price?.tablet,
                                        styles?.ui?.OfferCard?.price?.desktop
                                    )}
                                >
                                    {strikedPriceText}
                                </HeadingText3>
                                <div
                                    className={styles.twMerge(
                                        styles?.ui?.OfferCard?.timeUnit?.mobile,
                                        styles?.ui?.OfferCard?.timeUnit?.tablet,
                                        styles?.ui?.OfferCard?.timeUnit?.desktop
                                    )}
                                >
                                    <BodyText2>{timeUnit}</BodyText2>
                                </div>
                            </div>
                        </div>
                    </ConditionalRender>
                    <ConditionalRender condition={!isAcp && !isLifeline}>
                        {/* <ConditionalRender condition={topServiceableCompany === 'optimum' && offerValue === '1 Gig'}>
                            <div
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.strikedPrice?.mobile,
                                    styles?.ui?.OfferCard?.strikedPrice?.tablet,
                                    styles?.ui?.OfferCard?.strikedPrice?.desktop,
                                    'text-center'
                                )}
                            >
                                <BodyText2>{strikedPriceText}</BodyText2>
                            </div>
                        </ConditionalRender> */}
                        <div
                            className={
                                styles.twMerge(
                                    styles?.ui?.OfferCard?.mainPriceContainer?.mobile,
                                    styles?.ui?.OfferCard?.mainPriceContainer?.tablet,
                                    styles?.ui?.OfferCard?.mainPriceContainer?.desktop
                                ) + ' flex flex-col items-center'
                            }
                        >
                            <div className="flex flex-row">
                                <HeadingText3
                                    className={styles.twMerge(
                                        styles?.ui?.OfferCard?.price?.mobile,
                                        styles?.ui?.OfferCard?.price?.tablet,
                                        styles?.ui?.OfferCard?.price?.desktop
                                    )}
                                >
                                    {topServiceableCompany === 'optimum' && offerValue === '1 Gig'
                                        ? priceText
                                        : strikedPriceText}
                                </HeadingText3>
                                <div
                                    className={styles.twMerge(
                                        styles?.ui?.OfferCard?.timeUnit?.mobile,
                                        styles?.ui?.OfferCard?.timeUnit?.tablet,
                                        styles?.ui?.OfferCard?.timeUnit?.desktop
                                    )}
                                >
                                    <BodyText2>{timeUnit}</BodyText2>
                                </div>
                            </div>
                        </div>
                    </ConditionalRender>
                    <ConditionalRender condition={topServiceableCompany === 'spectrum' && offerValue !== '1 Gig'}>
                        <div className="flex flex-col items-center">
                            <BodyText4>{`$${(strikedPriceText.replace('$', '') - 10.0).toFixed(2)}/mo. + $10/mo.`}</BodyText4>
                            <BodyText4>equipment rental</BodyText4>
                        </div>
                    </ConditionalRender>
                    <Button
                        type="fill"
                        className={styles.twMerge(
                            styles?.ui?.OfferCard?.selectButton?.mobile,
                            styles?.ui?.OfferCard?.selectButton?.tablet,
                            styles?.ui?.OfferCard?.selectButton?.desktop
                        )}
                        onClick={() => {
                            onClick()
                        }}
                    >
                        {buttonCopy}
                    </Button>
                </div>
            </div>
            <div
                className={styles.twMerge(
                    styles?.ui?.OfferCard?.body?.mobile,
                    styles?.ui?.OfferCard?.body?.tablet,
                    styles?.ui?.OfferCard?.body?.desktop
                )}
            >
                <ul
                    className={styles.twMerge(
                        styles?.ui?.OfferCard?.shortDescriptionUl?.mobile,
                        styles?.ui?.OfferCard?.shortDescriptionUl?.tablet,
                        styles?.ui?.OfferCard?.shortDescriptionUl?.desktop
                    )}
                >
                    <ConditionalRender condition={badge}>
                        <TitleText3
                            className={styles.twMerge(
                                styles?.ui?.OfferCard?.Badge?.mobile,
                                styles?.ui?.OfferCard?.Badge?.tablet,
                                styles?.ui?.OfferCard?.Badge?.desktop
                            )}
                        >
                            {badge}
                        </TitleText3>
                    </ConditionalRender>
                    <li
                        className={
                            // eslint-disable-next-line no-nested-ternary
                            config?.tenant === 'freeconnect'
                                ? styles.twMerge(
                                      styles?.ui?.OfferCard?.shortDescriptionLi?.mobile,
                                      styles?.ui?.OfferCard?.shortDescriptionLi?.tablet,
                                      styles?.ui?.OfferCard?.shortDescriptionLi?.desktop
                                  )
                                : topServiceableCompany === 'spectrum'
                                  ? styles.twMerge(
                                        styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.mobile,
                                        styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.tablet,
                                        styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.desktop
                                    )
                                  : styles.twMerge(
                                        styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.mobile,
                                        styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.tablet,
                                        styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.desktop
                                    )
                        }
                    >
                        <BodyText2>{shortDescription}</BodyText2>
                    </li>
                    <ConditionalRender condition={topServiceableCompany === 'spectrum'}>
                        <li
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                config?.tenant === 'freeconnect'
                                    ? styles.twMerge(
                                          styles?.ui?.OfferCard?.shortDescriptionLiCheckMark?.mobile,
                                          styles?.ui?.OfferCard?.shortDescriptionLiCheckMark?.tablet,
                                          styles?.ui?.OfferCard?.shortDescriptionLiCheckMark?.desktop
                                      )
                                    : topServiceableCompany === 'spectrum'
                                      ? styles.twMerge(
                                            styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.mobile,
                                            styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.tablet,
                                            styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.desktop
                                        )
                                      : styles.twMerge(
                                            styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.mobile,
                                            styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.tablet,
                                            styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.desktop
                                        )
                            }
                        >
                            <BodyText2>{equipmentDescription}</BodyText2>
                        </li>
                        <li
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                config?.tenant === 'freeconnect'
                                    ? styles.twMerge(
                                          styles?.ui?.OfferCard?.shortDescriptionLiCalendar?.mobile,
                                          styles?.ui?.OfferCard?.shortDescriptionLiCalendar?.tablet,
                                          styles?.ui?.OfferCard?.shortDescriptionLiCalendar?.desktop
                                      )
                                    : topServiceableCompany === 'spectrum' || topServiceableCompany === 'frontier'
                                      ? styles.twMerge(
                                            styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.mobile,
                                            styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.tablet,
                                            styles?.ui?.OfferCard?.shortDescriptionLiBlueDiamond?.desktop
                                        )
                                      : styles.twMerge(
                                            styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.mobile,
                                            styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.tablet,
                                            styles?.ui?.OfferCard?.shortDescriptionWifiSignal?.desktop
                                        )
                            }
                        >
                            <BodyText2>{priceLockDescription}</BodyText2>
                        </li>
                    </ConditionalRender>
                    <ConditionalRender condition={hboMaxOfferLength}>
                        <li
                            className={styles.twMerge(
                                styles?.ui?.OfferCard?.shortDescriptionLiHbo?.mobile,
                                styles?.ui?.OfferCard?.shortDescriptionLiHbo?.tablet,
                                styles?.ui?.OfferCard?.shortDescriptionLiHbo?.desktop
                            )}
                        >
                            <TitleText1> +{hboMaxOfferLength} mo. Max subscription</TitleText1>
                        </li>
                    </ConditionalRender>
                    <ConditionalRender condition={mobileGift}>
                        <TitleText1
                            className={styles.twMerge(
                                styles?.ui?.OfferCard?.mobileGift?.mobile,
                                styles?.ui?.OfferCard?.mobileGift?.tablet,
                                styles?.ui?.OfferCard?.mobileGift?.desktop
                            )}
                        >
                            <div
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.mobileGiftText?.mobile,
                                    styles?.ui?.OfferCard?.mobileGiftText?.tablet,
                                    styles?.ui?.OfferCard?.mobileGiftText?.desktop
                                )}
                            >
                                {mobileGift}
                            </div>
                            <img
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.mobileGiftImage?.mobile,
                                    styles?.ui?.OfferCard?.mobileGiftImage?.tablet,
                                    styles?.ui?.OfferCard?.mobileGiftImage?.desktop
                                )}
                                src={phoneImage.url}
                                alt={phoneImage.alt}
                            />
                        </TitleText1>
                    </ConditionalRender>
                    <ConditionalRender condition={giftCard}>
                        <TitleText1
                            className={styles.twMerge(
                                styles?.ui?.OfferCard?.giftCard?.mobile,
                                styles?.ui?.OfferCard?.giftCard?.tablet,
                                styles?.ui?.OfferCard?.giftCard?.desktop
                            )}
                        >
                            <div
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.giftCardText?.mobile,
                                    styles?.ui?.OfferCard?.giftCardText?.tablet,
                                    styles?.ui?.OfferCard?.giftCardText?.desktop
                                )}
                            >
                                {giftCard}
                            </div>
                            <img
                                className={styles.twMerge(
                                    styles?.ui?.OfferCard?.giftCardImage?.mobile,
                                    styles?.ui?.OfferCard?.giftCardImage?.tablet,
                                    styles?.ui?.OfferCard?.giftCardImage?.desktop
                                )}
                                src={presentImage.url}
                                alt={presentImage.alt}
                            />
                        </TitleText1>
                    </ConditionalRender>
                </ul>
                <ConditionalRender condition={showMore}>
                    <ul
                        className={styles.twMerge(
                            styles?.ui?.OfferCard?.sectionUl?.mobile,
                            styles?.ui?.OfferCard?.sectionUl?.tablet,
                            styles?.ui?.OfferCard?.sectionUl?.desktop
                        )}
                    >
                        {sections?.map((section: { title: string; bullets: string[]; giftCard: string }) => {
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <li
                                    className={styles.twMerge(
                                        styles?.ui?.OfferCard?.sectionLi?.mobile,
                                        styles?.ui?.OfferCard?.sectionLi?.tablet,
                                        styles?.ui?.OfferCard?.sectionLi?.desktop
                                    )}
                                >
                                    <Button
                                        className={styles.twMerge(
                                            styles?.ui?.OfferCard?.sectionTitle?.main?.mobile,
                                            styles?.ui?.OfferCard?.sectionTitle?.main?.tablet,
                                            styles?.ui?.OfferCard?.sectionTitle?.main?.desktop
                                        )}
                                        onClick={() => {
                                            setShowMoreSections((prev: any) => ({
                                                ...prev,
                                                [section?.title]: !prev?.[section?.title],
                                            }))
                                        }}
                                    >
                                        <TitleText2
                                            className={styles.twMerge(
                                                styles?.ui?.OfferCard?.sectionTitle?.text?.mobile,
                                                styles?.ui?.OfferCard?.sectionTitle?.text?.tablet,
                                                styles?.ui?.OfferCard?.sectionTitle?.text?.desktop
                                            )}
                                        >
                                            {section?.title}
                                            <ConditionalRender condition={showMoreSections[section?.title]}>
                                                <GrUp />
                                            </ConditionalRender>
                                            <ConditionalRender condition={!showMoreSections[section?.title]}>
                                                <GrDown />
                                            </ConditionalRender>
                                        </TitleText2>
                                    </Button>
                                    <ConditionalRender condition={showMoreSections?.[section?.title]}>
                                        <ul
                                            className={styles.twMerge(
                                                styles?.ui?.OfferCard?.sectionListUl?.mobile,
                                                styles?.ui?.OfferCard?.sectionListUl?.tablet,
                                                styles?.ui?.OfferCard?.sectionListUl?.desktop
                                            )}
                                        >
                                            {section?.bullets?.map((bullet: string) => {
                                                return (
                                                    // eslint-disable-next-line react/jsx-key
                                                    <li
                                                        className={styles.twMerge(
                                                            styles?.ui?.OfferCard?.sectionListLi?.mobile,
                                                            styles?.ui?.OfferCard?.sectionListLi?.tablet,
                                                            styles?.ui?.OfferCard?.sectionListLi?.desktop
                                                        )}
                                                    >
                                                        <BodyText2>{bullet}</BodyText2>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </ConditionalRender>
                                </li>
                            )
                        })}
                    </ul>
                </ConditionalRender>
                <ShowMore
                    className={styles.twMerge(
                        styles?.ui?.OfferCard?.showMore?.main?.mobile,
                        styles?.ui?.OfferCard?.showMore?.main?.tablet,
                        styles?.ui?.OfferCard?.showMore?.main?.desktop
                    )}
                    textClassName={styles.twMerge(
                        styles?.ui?.OfferCard?.showMore?.text?.mobile,
                        styles?.ui?.OfferCard?.showMore?.text?.tablet,
                        styles?.ui?.OfferCard?.showMore?.text?.desktop
                    )}
                    moreCopy={showMoreCopy}
                    lessCopy={showLessCopy}
                    onClick={(isShowMore: Boolean) => {
                        setShowMore(isShowMore)
                    }}
                />
            </div>
        </Card>
    )
}

export default OfferCard
