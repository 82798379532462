/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { PopupContext } from '../contexts/Popup'

function Link({ className = '', children, href, type = 'default', onClick = () => {} }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [, popupDispatch]: any = useContext(PopupContext)

    if (href.startsWith('popup:')) {
        return (
            <button
                type="button"
                className={styles.twMerge(
                    styles?.ui?.Link?.[type]?.button?.mobile,
                    styles?.ui?.Link?.[type]?.button?.tablet,
                    styles?.ui?.Link?.[type]?.button?.desktop,
                    className
                )}
                onClick={() => {
                    popupDispatch({
                        type: 'ADD_POPUP',
                        payload: {
                            name: href.split(':')[1],
                            componentToRender: href.split(':')[1],
                        },
                    })
                }}
            >
                {children}
            </button>
        )
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <a
            target="_blank"
            className={styles.twMerge(
                styles?.ui?.Link?.[type]?.a?.mobile,
                styles?.ui?.Link?.[type]?.a?.tablet,
                styles?.ui?.Link?.[type]?.a?.desktop,
                className
            )}
            href={href}
            onClick={onClick}
            rel="noreferrer"
        >
            {children}
        </a>
    )
}

export default Link
