/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// Packages
import React, { useContext, useState, useRef, useEffect, useLayoutEffect } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'

function ScrollV2({ className = '', wrapperClassName = '', children }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)

    // State
    const [isScrolledTop, setIsScrolledTop] = useState(true)
    const [isScrolledBottom, setIsScrolledBottom] = useState(false)
    const [windowWidth, setwindowWidth] = useState(0)
    const [windowHeight, setwindowHeight] = useState(0)
    const [height, setHeight] = useState(0)
    const [isContentBigger, setIsConentBigger] = useState(false)

    // Refs
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const handleScroll = () => {
        const container = scrollContainerRef.current
        if (container) {
            const isAtTop = container.scrollTop === 0
            const isAtBottom = container.scrollHeight - container.scrollTop === container.clientHeight

            setIsScrolledTop(isAtTop)
            setIsScrolledBottom(isAtBottom)
        }
    }

    // make sure the initial height matches the content!
    // prevents the height from being wrong the first time modal opens
    useLayoutEffect(() => {
        const wrapperHeight = wrapperRef.current?.offsetHeight
        setHeight(wrapperHeight ?? 0)
    }, [windowHeight, windowWidth, isContentBigger])

    useEffect(() => {
        const containerHeight = scrollContainerRef.current?.offsetHeight ?? 0
        const contentHeight = wrapperRef.current?.offsetHeight ?? 0
        const conterIsBiggerThanContainer = contentHeight < containerHeight
        setIsConentBigger(conterIsBiggerThanContainer)
    }, [windowWidth, windowHeight])

    useEffect(() => {
        const handleResize = () => {
            setwindowHeight(window.innerHeight)
            setwindowWidth(window.innerWidth)
        }
        // get the initial size!
        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const container = scrollContainerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
            handleScroll()
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    return (
        <div
            style={{
                height: `${height}px`,
            }}
            className={styles?.twMerge(className, 'relative overflow-hidden')}
        >
            {/* Fading Top Line */}
            <div
                className={`absolute inset-x-0 top-0 h-10 pointer-events-none z-40 ${
                    isScrolledTop
                        ? 'bg-transparent'
                        : 'bg-gradient-to-b from-white from-[1.37%] to-transparent to-[72.03%]'
                }`}
            />

            {/* Scrollable Content */}
            <div
                ref={scrollContainerRef}
                className={styles?.twMerge(wrapperClassName, 'relative h-full px-3 overflow-y-auto')}
            >
                <div className="pb-3" ref={wrapperRef}>
                    {children}
                </div>
            </div>

            {/* Fading Bottom Line */}
            <div
                className={`absolute inset-x-0 bottom-0 h-10 pointer-events-none z-40 ${
                    isScrolledBottom
                        ? 'bg-transparent'
                        : 'bg-gradient-to-t from-white from-[1.37%] to-transparent to-[72.03%]'
                }`}
            />
        </div>
    )
}

export default ScrollV2
