/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
import ConditionalRender from '../../components/ConditionalRender'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import AmountDue from '../../ui/AmountDue'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import Button from '../../ui/Button'
import Checkbox from '../../ui/Checkbox'
import BodyText5 from '../../ui/BodyText5'
import Footer from '../../ui/Footer'
import Header from '../../ui/Header'
import InfoCard from '../../ui/InfoCard'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
import { QueryParamsContext } from '../../contexts/QueryParams'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import { addDashesToPhone } from '../../helpers/parsePhoneNumber'
import makeBackendRequest from '../../helpers/backend'
import getConfigData from '../../helpers/config/getConfigData'
// Helpers
import { requireNonEmptyValue } from '../../helpers/validation/unitFunctions'

function NotAcpTransferable({ onNext, step, progressBar }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const [clicked, setClicked] = React.useState(false)
    const [selectSpectrumConfirm, setSelectSpectrumConfirm] = React.useState(false)
    const [queryParams]: any = useContext(QueryParamsContext)

    // State
    const [infoCardType, setInfoCardType] = React.useState('branded')
    const [firstMonthBillValue, setFirstMonthBillValue]: any = React.useState(null)
    // eslint-disable-next-line no-unused-vars
    const [recurringBillValue, setRecurringBillValue]: any = React.useState(null)
    const [fundleImage, setFundleImage]: any = React.useState({})

    useEffect(() => {
        // Set up special info card types
        if (formData?.topServiceableCompany === 'spectrum') {
            const recurringBillNumber = formData?.selectedPlanStrikePrice.replace('$', '')
            let monthlyBillNumber = null
            if (formData?.selectedBundle) {
                setInfoCardType('bundledOffer')
                setRecurringBillValue(recurringBillNumber)
                monthlyBillNumber =
                    parseFloat(formData?.selectedBundle?.totalBundlePrice.replace('$', '')) +
                    parseFloat(
                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.infoCard
                            ?.oneTimeFeePrice
                    )
                setFirstMonthBillValue(monthlyBillNumber)
            } else {
                setInfoCardType('spectrum')
                setRecurringBillValue(recurringBillNumber)
                monthlyBillNumber = (
                    parseFloat(recurringBillNumber) +
                    parseFloat(formData?.equipmentPrice || 0) +
                    parseFloat(
                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.infoCard
                            ?.oneTimeFeePrice
                    )
                ).toFixed(2)
                setFirstMonthBillValue(monthlyBillNumber)
            }
            formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: 'customerFirstBill',
                    value: monthlyBillNumber || recurringBillNumber,
                },
            })
            formDataDispatch({
                type: 'SET_FIELD',
                payload: { name: 'customerAgreedPrice', value: recurringBillNumber },
            })
        } else {
            // Company is not spectrum
            // NOTE: Since no companies have bundles yet or first month fees other than spectrum, we can set first month as recurring bill price
            formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: 'customerFirstBill',
                    value: formData?.selectedPlanStrikePrice.replace('$', ''),
                },
            })
            formDataDispatch({
                type: 'SET_FIELD',
                payload: { name: 'customerAgreedPrice', value: formData?.selectedPlanStrikePrice.replace('$', '') },
            })
        }
    }, [])

    useEffect(() => {
        if (formData?.isOrderSubmitted) {
            if (formData?.topServiceableCompany === 'spectrum') {
                let lastStepHolder
                if (formData?.seenBundlePage) {
                    lastStepHolder = 9
                } else {
                    lastStepHolder = 8
                }
                if (step === lastStepHolder && formData.confirmedSpectrumOrder) {
                    onNext()
                } else if (config.formName === 'welcomexaddress') {
                    onNext()
                } else {
                    setSelectSpectrumConfirm(true)
                }
            } else {
                onNext()
            }
        }
    }, [formData?.isOrderSubmitted, clicked])

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    useEffect(() => {
        if (formData?.isOrderSubmitted === true) {
            makeBackendRequest(
                { stack: 'ses', endpoint: 'sendToSqs' },
                {
                    config,
                    formData,
                    body: {
                        use_case: `${formData.topServiceableCompany}_app_received`,
                        recipients_email: formData.email,
                        cnxId: formData.connexID,
                    },
                }
            )
        }
    }, [formData?.isOrderSubmitted])

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'Fundle-Bundle-Promo.png',
        }).then((data: { [key: string]: any }) => {
            setFundleImage(data)
        })
    }, [])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="submitOrderBasicNonAcpHeader">
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.title,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ],
                                    }
                                )}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="submitOrderBasicNonAcpSubHeader">
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.subHeader,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ],
                                    }
                                )}
                            </StyleCopy>
                        </SubHeading>
                        <InfoCard
                            topServiceableCompany={formData?.topServiceableCompany}
                            type={infoCardType}
                            plan={formData?.selectedPlan}
                            planPrice={`$${(
                                parseFloat(formData?.selectedPlanStrikePrice.replace('$', '')) -
                                parseFloat(formData?.selectedPlanEquipmentCost || 0)
                            ).toFixed(2)}`}
                            image={{}}
                            fullName={
                                formData?.middleName
                                    ? `${formData.firstName} ${formData.middleName} ${formData.lastName}`
                                    : `${formData.firstName} ${formData.lastName}`
                            }
                            phoneNumber={addDashesToPhone(formData.phoneNumber)}
                            address={formData.fullAddress}
                            deviceCopy={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.infoCard?.device?.title
                            }
                            devicePriceCopy={formData?.selectedPlanEquipmentCost}
                            monthlyPriceCopy={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.infoCard?.monthlyTitle
                            }
                            monthlyPriceValue={formData?.selectedPlanStrikePrice.replace('$', '')}
                            selectedPlanOriginalPrice={formData?.selectedPlanOriginalPrice.replace('$', '')}
                            taxesCopy={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.infoCard?.taxesMessage
                            }
                            oneTimeFeeCopy={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.infoCard?.oneTimeFeeTitle
                            }
                            oneTimeFeeValue={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.infoCard?.oneTimeFeePrice
                            }
                            firstMonthBillCopy={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.infoCard?.firstMonthsBillTitle
                            }
                            firstMonthBillValue={firstMonthBillValue}
                            billsDisclosure={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.infoCard?.billsDisclosure
                            }
                            planTypeCopy={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.infoCard?.planSelected,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            }
                            titleCopy={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.infoCard?.title,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            }
                            nameCopy={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.infoCard?.name,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            }
                            phoneCopy={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.infoCard?.phone,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            }
                            addressCopy={
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.infoCard?.address,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            }
                        />
                        <ConditionalRender
                            condition={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.amountDue?.dueAmount
                            }
                        >
                            <AmountDue
                                name="submitOrderBasicAcp"
                                type={formData?.topServiceableCompany === 'spectrum' ? 'Highlight' : 'Default'}
                                dueCopy={
                                    <StyleCopy>
                                        {parseCopy(
                                            copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ]?.amountDue?.dueAmount,
                                            {
                                                config,
                                                formData,
                                                copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                    formData?.topServiceableCompany
                                                ],
                                            }
                                        )}
                                    </StyleCopy>
                                }
                                disclosureCopy={
                                    <StyleCopy>
                                        {parseCopy(
                                            copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ]?.amountDue?.disclosure,
                                            {
                                                config,
                                                formData,
                                                copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                    formData?.topServiceableCompany
                                                ],
                                            }
                                        )}
                                    </StyleCopy>
                                }
                            >
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.amountDue?.amount,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            </AmountDue>
                        </ConditionalRender>
                        <div
                        // className={styles.twMerge(
                        //     styles?.steps?.submitOrderBasic?.NonAcpTransferable?.requestBroadbandLabel?.mobile,
                        //     styles?.steps?.submitOrderBasic?.NonAcpTransferable?.requestBroadbandLabel?.tablet,
                        //     styles?.steps?.submitOrderBasic?.NonAcpTransferable?.requestBroadbandLabel?.desktop
                        // )}
                        >
                            <Checkbox
                                step={step}
                                name="requestBroadbandLabel"
                                initialValue={formData.isBroadbandLabelRequested}
                                popup={
                                    copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.broadBandLabelPopup
                                }
                                onChange={(value: boolean) => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'isBroadbandLabelRequested', value },
                                    })
                                }}
                            >
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.requestBroadbandLabel,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            </Checkbox>
                        </div>
                        <ConditionalRender condition={formData.topServiceableCompany === 'spectrum'}>
                            <div>
                                <Checkbox
                                    step={step}
                                    validationFunctions={[requireNonEmptyValue]}
                                    formDataKey="confirmedSpectrumOrder"
                                    name="confirmedSpectrumOrder"
                                    initialValue={false}
                                    onChange={(value: boolean) => {
                                        formDataDispatch({
                                            type: 'SET_FIELD',
                                            payload: { name: 'confirmedSpectrumOrder', value },
                                        })
                                    }}
                                >
                                    <StyleCopy>
                                        {
                                            copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ]?.confirmOrderCheckboxTitle
                                        }
                                    </StyleCopy>
                                </Checkbox>
                            </div>
                            <ConditionalRender condition={selectSpectrumConfirm}>
                                <p className="text-red-500">Please confirm your order before continuing.</p>
                            </ConditionalRender>
                        </ConditionalRender>
                        <Button
                            type="fill"
                            className={styles.twMerge(
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.button?.mobile,
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.button?.tablet,
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.button?.desktop
                            )}
                            name="submitOrderBasicNonAcpSubmit"
                            onClick={() => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'isOrderSubmitted', value: true },
                                })
                                setClicked(!clicked)
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.submitButton,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ],
                                    }
                                )}
                            </StyleCopy>
                        </Button>
                        <ConditionalRender
                            condition={
                                formData?.topServiceableCompany === 'spectrum' && config?.formName === 'fundleFormP3'
                            }
                        >
                            <div className="flex flex-row justify-center w-full">
                                <img
                                    className="pb-[20px] max-w-[388px] w-full"
                                    src={fundleImage.url}
                                    alt={fundleImage.alt}
                                />
                            </div>
                        </ConditionalRender>
                        <BodyText5
                            className={styles.twMerge(
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.disclaimerTitle?.mobile,
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.disclaimerTitle?.tablet,
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.disclaimerTitle?.desktop
                            )}
                            name="submitOrderBasicNonAcpFooterTitle"
                        >
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.footerText?.title,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ],
                                    }
                                )}
                            </StyleCopy>
                        </BodyText5>
                        <div
                            className={styles.twMerge(
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.disclaimerContainer?.mobile,
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.disclaimerContainer?.tablet,
                                styles?.steps?.submitOrderBasic?.NonAcpTransferable?.disclaimerContainer?.desktop
                            )}
                        >
                            <BodyText5 name="submitOrderBasicNonAcpFooterOne">
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.footerText?.disclaimerOne,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText5>
                            <BodyText5 name="submitOrderBasicNonAcpFooterTwo">
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ]?.footerText?.disclaimerTwo,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ],
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText5>
                            <ConditionalRender
                                condition={
                                    copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.footerText?.disclaimerThree
                                }
                            >
                                <BodyText5 name="submitOrderBasicAcpFooterThree">
                                    <StyleCopy>
                                        {parseCopy(
                                            copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ]?.footerText?.disclaimerThree,
                                            {
                                                config,
                                                formData,
                                                copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                    formData?.topServiceableCompany
                                                ],
                                            }
                                        )}
                                    </StyleCopy>
                                </BodyText5>
                            </ConditionalRender>
                        </div>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default NotAcpTransferable
