// Packages
import React from 'react'
// Forms
import InterimFormP2 from '../../forms/InterimFormP2/index'
import InterimFormP3 from '../../forms/InterimFormP3/index'
import Loading from '../../forms/Loading/index'
import BasicFormManychat from '../../forms/BasicManychatForm/index'
import SesUnsubscribe from '../../forms/SesUnsubscribe'
import SpectrumInboundSuccess from '../../forms/SpectrumInboundSuccess'
import SpecNo50offer from '../../forms/SpecNo50offer'
import OffersFirst from '../../forms/OffersFirst'
import InterimFormLifelineDiscount from '../../forms/InterimFormLifelineDiscount'
import FundleFormP3 from '../../forms/FundleFormP3'
import WelcomeXAddress from '../../forms/WelcomeXAddress'

const formNameMapObject: { [index: string]: React.ReactElement } = {
    interimFormP2: <InterimFormP2 />,
    interimFormP3: <InterimFormP3 />,
    loading: <Loading />,
    default: <InterimFormP3 />,
    basicManychatForm: <BasicFormManychat />,
    SesUnsubscribe: <SesUnsubscribe />,
    spectrumInboundSuccess: <SpectrumInboundSuccess />,
    specNo50offer: <SpecNo50offer />,
    offersFirst: <OffersFirst />,
    interimformlifelinediscount: <InterimFormLifelineDiscount />,
    fundleFormP3: <FundleFormP3 />,
    welcomeXAddress: <WelcomeXAddress />,
}

const formNameMap = ({ key, config }: { key: string; config: string }) =>
    React.cloneElement(formNameMapObject?.[key] ? formNameMapObject[key] : formNameMapObject.default, { config })

export default formNameMap
