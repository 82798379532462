/* eslint-disable no-unused-vars */
import { useEffect, useContext, useRef } from 'react'
import { FormDataContext } from '../contexts/FormData'

function SetUpDemoFormTestData() {
    // const [copy]: any = useContext(CopyContext)
    // const [formData, formDataDispatch]: any = useContext(FormDataContext)
    // const isFirstRender = useRef(true)

    // useEffect(() => {
    //     if (isFirstRender.current) {
    //         isFirstRender.current = false
    //         if ('addressTimerThrottle' in formData === false) {
    //             const timerBool = Math.random() < 0.5
    //             formDataDispatch({
    //                 type: 'SET_FIELD',
    //                 payload: {
    //                     name: 'addressTimerThrottle',
    //                     value: timerBool,
    //                 },
    //             })
    //         }
    //     }
    // }, [formData, formDataDispatch])

    return null
}

export default SetUpDemoFormTestData
