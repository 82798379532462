/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext, useState, useRef, forwardRef, useImperativeHandle } from 'react'

import { AiOutlineExclamationCircle } from 'react-icons/ai'
// Components
import ConditionalRender from '../components/ConditionalRender'
// Contexts
import { ValidationContext } from '../contexts/Validation'
import { CopyContext } from '../contexts/Copy'
import { ConfigContext } from '../contexts/Config'
import { StylesContext } from '../contexts/Styles'
// Helpers
import { validateSingleField } from '../helpers/validation/main'
import stringReplace from '../helpers/stringReplace'
import { capitalizeFirstLetter } from '../helpers/string'
import { phoneNumberAutoFormat, removeDashesFromPhone } from '../helpers/parsePhoneNumber'
// UI
import BodyText2 from './BodyText2'
import StyleCopy from '../components/StyleCopy'
// eslint-disable-next-line import/no-named-as-default
import parseCopy from '../helpers/parseCopy'
import { FormDataContext } from '../contexts/FormData'

// Interface
// {
//     autoComplete: string
//     children: React.ReactElement | string
//     className: string
//     inputClassName: string
//     disabled: boolean | undefined
//     name: string
//     onChange: Function
//     placeholder: string
//     isNumberType: boolean
// }

const TextFieldV2 = forwardRef(
    (
        {
            step = null,
            autoComplete = 'on',
            children,
            className = '',
            inputClassName = '',
            dropdownClassName = '',
            disabled = undefined,
            name,
            onChange = () => {},
            placeholder,
            isNumberType,
            validationFunctions = [],
            formDataKey = '',
            disableValidation = false,
            disableOnChangeValidation = false,
            value = '',
            subLabel = '',
            errorClassName = '',
            staticValue,
            dropdown,
            disableDropdown = false,
            phoneNumber = false,
            hideErrorMessage = false,
        }: any,
        ref
    ) => {
        // Contexts
        const [validation, validationDispatch]: any = useContext(ValidationContext)
        const [copy]: any = useContext(CopyContext)
        const [config]: any = useContext(ConfigContext)
        const [styles]: any = useContext(StylesContext)
        const [formData]: any = useContext(FormDataContext)
        // States
        const [createdValidation, setCreatedValidation] = useState(false)
        const [waitToValidate, setWaitToValidate] = useState(false)
        const [errorMessage, setErrorMessage] = useState('')
        // Refs
        const field = useRef<HTMLLabelElement>(null)

        const scrollToField = () => {
            field?.current?.scrollIntoView({
                behavior: 'smooth',
            })
        }

        useImperativeHandle(ref, () => ({
            focus: () => {
                field?.current?.scrollIntoView({ behavior: 'smooth' })
                const input = field?.current?.querySelector('input')
                input?.focus()
            },
        }))

        useEffect(() => {
            if (!config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
                if ((step || step === 0) && name && !createdValidation && !disableValidation) {
                    validationDispatch({
                        type: 'ADD_FIELD',
                        payload: {
                            step,
                            name,
                            formDataKey,
                            functions: validationFunctions,
                        },
                    })
                    setCreatedValidation(true)
                }
            }
        }, [config?.tenantConfig?.featureFlags, createdValidation, formDataKey, name, step, validationFunctions])

        useEffect(() => {
            if ((step || step === 0) && name) {
                if (disableValidation) {
                    validationDispatch({
                        type: 'REMOVE_FIELDS',
                        payload: {
                            step,
                            names: [name],
                        },
                    })
                    validationDispatch({
                        type: 'ADD_ERROR',
                        payload: {
                            [formDataKey]: '',
                        },
                    })
                } else {
                    validationDispatch({
                        type: 'ADD_FIELD',
                        payload: {
                            step,
                            name,
                            formDataKey,
                            functions: validationFunctions,
                        },
                    })
                }
            }
        }, [disableValidation])

        useEffect(() => {
            const asyncWrapper = async () => {
                const { message, metaData } = await validateSingleField(
                    phoneNumber ? removeDashesFromPhone(value) : value,
                    validationFunctions
                )
                setErrorMessage(
                    stringReplace(copy?.validationErrors?.[message] ? copy?.validationErrors?.[message] : '', metaData)
                )
            }

            if (
                waitToValidate &&
                !disableOnChangeValidation &&
                !config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`] &&
                !disableValidation
            ) {
                asyncWrapper()
            }
        }, [
            value,
            validationFunctions,
            waitToValidate,
            disableOnChangeValidation,
            copy?.validationErrors,
            config?.tenantConfig?.featureFlags,
            name,
        ])

        useEffect(() => {
            if (validation?.errors?.errorMessages?.[name] || validation?.errors?.errorMessages?.[name] === '') {
                if (errorMessage !== validation.errors.errorMessages[name]) {
                    let message = validation.errors.errorMessages[name]
                    if (message !== '') {
                        message = stringReplace(
                            copy?.validationErrors?.[validation.errors.errorMessages[name]]
                                ? copy?.validationErrors?.[validation.errors.errorMessages[name]]
                                : '',
                            validation.errors.metaDatas[name]
                        )
                    }
                    if (message !== '') {
                        scrollToField()
                    }
                    setErrorMessage(message)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [validation.errors])

        if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
            return null
        }

        if (Object.keys(styles).length === 0) {
            return null
        }

        return (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label
                ref={field}
                className={styles.twMerge(
                    styles?.ui?.TextField?.label?.mobile,
                    styles?.ui?.TextField?.label?.tablet,
                    styles?.ui?.TextField?.label?.desktop,
                    className
                )}
            >
                {children}
                <div
                    className={
                        subLabel
                            ? styles.twMerge(
                                  styles?.ui?.TextField?.subLabel?.mobile,
                                  styles?.ui?.TextField?.subLabel?.tablet,
                                  styles?.ui?.TextField?.subLabel?.desktop,
                                  className
                              )
                            : 'hidden'
                    }
                >
                    {subLabel}
                </div>
                <input
                    value={value}
                    autoComplete={autoComplete}
                    className={
                        dropdown && !disableDropdown
                            ? styles.twMerge(
                                  styles?.ui?.TextField?.input?.mobile,
                                  styles?.ui?.TextField?.input?.tablet,
                                  styles?.ui?.TextField?.input?.desktop,
                                  styles?.ui?.TextField?.input?.active,
                                  styles?.ui?.TextField?.input?.focus,
                                  styles?.ui?.TextField?.input?.hover,
                                  styles?.ui?.TextField?.inputDropDown?.mobile,
                                  styles?.ui?.TextField?.inputDropDown?.tablet,
                                  styles?.ui?.TextField?.inputDropDown?.desktop,
                                  styles?.ui?.TextField?.inputDropDown?.active,
                                  styles?.ui?.TextField?.inputDropDown?.focus,
                                  styles?.ui?.TextField?.inputDropDown?.hover,
                                  errorMessage ? styles?.ui?.TextField?.input?.error : '',
                                  inputClassName
                              )
                            : styles.twMerge(
                                  styles?.ui?.TextField?.input?.mobile,
                                  styles?.ui?.TextField?.input?.tablet,
                                  styles?.ui?.TextField?.input?.desktop,
                                  styles?.ui?.TextField?.input?.active,
                                  styles?.ui?.TextField?.input?.focus,
                                  styles?.ui?.TextField?.input?.hover,
                                  errorMessage ? styles?.ui?.TextField?.input?.error : '',
                                  hideErrorMessage ? 'mb-0' : '',
                                  inputClassName
                              )
                    }
                    disabled={disabled}
                    name={name}
                    placeholder={placeholder}
                    type={isNumberType ? 'tel' : 'text'}
                    onChange={(event) => {
                        if (!(staticValue && value !== staticValue && event.target.value.length < value.length)) {
                            if (
                                (staticValue && event.target.value === staticValue) ||
                                (staticValue && value === staticValue && event.target.value.length < value.length)
                            ) {
                                return
                            }
                        }

                        let tempValue = event.target.value
                        let tempOnChange = event.target.value

                        if (phoneNumber) {
                            tempValue = phoneNumberAutoFormat(tempValue)
                            tempOnChange = removeDashesFromPhone(tempValue)
                        }

                        onChange(tempOnChange)
                        if (!waitToValidate) {
                            setWaitToValidate(true)
                        }
                    }}
                />
                <ConditionalRender condition={dropdown && !disableDropdown}>
                    <div
                        className={styles.twMerge(
                            'mb-0',
                            styles?.ui?.TextField?.dropdown?.mobile,
                            styles?.ui?.TextField?.dropdown?.tablet,
                            styles?.ui?.TextField?.dropdown?.desktop,
                            errorMessage ? styles?.ui?.TextField?.dropdown?.error : '',
                            dropdownClassName
                        )}
                    >
                        {dropdown}
                    </div>
                </ConditionalRender>
                {!hideErrorMessage && (
                    <ConditionalRender condition={errorMessage}>
                        <BodyText2
                            className={styles.twMerge(
                                styles?.ui?.TextField?.error?.mobile,
                                styles?.ui?.TextField?.error?.tablet,
                                styles?.ui?.TextField?.error?.desktop,
                                errorClassName
                            )}
                        >
                            <AiOutlineExclamationCircle
                                className={styles.twMerge(
                                    styles?.ui?.TextField?.errorIcon?.mobile,
                                    styles?.ui?.TextField?.errorIcon?.tablet,
                                    styles?.ui?.TextField?.errorIcon?.desktop,
                                    'shrink-0'
                                )}
                            />
                            &nbsp;
                            <StyleCopy>
                                {parseCopy(errorMessage, {
                                    config,
                                    formData,
                                    copy: copy?.validationErrors,
                                })}
                            </StyleCopy>
                        </BodyText2>
                    </ConditionalRender>
                )}
            </label>
        )
    }
)
TextFieldV2.displayName = 'TextFieldV2'

export default TextFieldV2
