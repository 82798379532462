/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { TbLoader2 } from 'react-icons/tb'
import { FaPhone } from 'react-icons/fa6'
import Button from '../../ui/Button'
import { FormDataContext } from '../../contexts/FormData'
import { ValidationContext } from '../../contexts/Validation'
import { ConfigContext } from '../../contexts/Config'
import { StepsContext } from '../../contexts/Steps'
import {
    requireAddressLine2IfAddressHasUnits,
    requireNonEmptyValue,
    requireNotPoBox,
    requireResidentialAddress,
    requireValidAddress,
    requireValidAddressLine2,
    smartyValidationV2,
} from '../../helpers/validation/unitFunctions'
import { StylesContext } from '../../contexts/Styles'
import BodyText2 from '../../ui/BodyText2'
import BodyText4 from '../../ui/BodyText4'
import TitleText2 from '../../ui/TitleText2'
import { CopyContext } from '../../contexts/Copy'
// eslint-disable-next-line import/no-named-as-default
import querySmartyAutoComplete from '../../helpers/smarty'
import { getSmartySuggestion } from '../../helpers/smartyV2'
import { Result, validateAddress } from '../../helpers/getSmartyClosestMatch'
import ConditionalRender from '../../components/ConditionalRender'
import { PopupContext } from '../../contexts/Popup'
import usStates from '../../helpers/supportData/usStates'
import Page from '../../ui/Page'
import SubHeading from '../../ui/SubHeading'
import getConfigData from '../../helpers/config/getConfigData'
import { QueryParamsContext } from '../../contexts/QueryParams'
import StyleCopy from '../../components/StyleCopy'
import Container from '../../ui/Container'
import Main from '../../ui/Main'
import TextFieldV2 from '../../ui/TextFieldV2'
import DropdownV2 from '../../ui/DropdownV2'
import StepperButtonContainer from '../../ui/StepperButtonContainer'
import NotificationPill from '../../ui/NotificationPill'
// eslint-disable-next-line import/no-named-as-default
import parseCopy from '../../helpers/parseCopy'

interface AddressAutocomplete {
    streetLine: string
    secondary: string
    city: string
    state: string
    zipcode: string
    entries: number
}

function WelcomeXAddress({ onNext }: any) {
    // contexts
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [popup, popupDispatch]: any = useContext(PopupContext)
    const [config]: any = useContext(ConfigContext)
    const [copy]: any = useContext(CopyContext)
    const [step]: any = useContext(StepsContext)
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)

    // state
    const [logo, setLogo] = useState<{ alt?: string; url?: string } | null>()
    const [image, setImage] = useState<{ alt?: string; url?: string } | null>()
    const [icon, setIcon] = useState<{ alt?: string; url?: string } | null>()

    const [isManualEntry, setIsManualEntry] = useState(false)
    const [street, setStreet] = useState<string>('')
    const [secondary, setSecondary] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [zip, setZip] = useState<string>('')
    const [numberOfFails, setNumberOfFails] = useState(0)

    const [isSecondaryRequired, setIsSecondaryRequired] = useState(true)
    const [isSecondaryShowing, setIsSecondaryShowing] = useState(false)
    const [isSecondaryExanded, setIsSecondaryExpanded] = useState(false)
    const [isCheckinAddress, setIsCheckingAddress] = useState(false)
    const [isBannerVisible, setIsBannerVisible] = useState(false)

    const [suggestedAddresses, setSuggestedAddresses] = useState<AddressAutocomplete[]>([])
    const [suggestedSecondaries, setSuggestedSecondaries] = useState<AddressAutocomplete[]>([])

    const [clickedStreetSuggestion, setClickedStreetSuggestion] = useState(true)
    const [clickedSecondarySuggestion, setClickedSecondarySuggestion] = useState(false)
    const [selectedStreet, setSelectedStreet] = useState<AddressAutocomplete | undefined>(undefined)
    const [analysisResult, setAnalysisResult] = useState<Result[]>([])

    // Refs
    const secondaryFieldRef = useRef<HTMLInputElement>(null)
    const primaryFieldRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'FcFreedaFriendHighFiveWithComputerBackground.png',
        }).then((data: any) => {
            setImage(data)
        })
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'BlueFreeConnectLogo.png',
        }).then((data: any) => {
            setLogo(data)
        })
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'ReturnIcon.svg',
        }).then((data: any) => {
            setIcon(data)
        })
    }, [])

    useEffect(() => {
        validationDispatch({
            type: 'ADD_FIELD',
            payload: {
                step: step.currentStep,
                name: 'address',
                formDataKeys: {
                    street: 'addressStreet',
                    secondary: 'addressSecondary',
                    city: 'addressCity',
                    state: 'addressState',
                    zip: 'addressZip',
                },
                functions: [
                    async (parameters: any) => {
                        const valid = smartyValidationV2(
                            { endpoint: '/', request: { config, formData } },
                            [
                                requireNotPoBox,
                                requireValidAddress,
                                requireResidentialAddress,
                                requireAddressLine2IfAddressHasUnits,
                                requireValidAddressLine2,
                            ],
                            parameters
                        )
                        return valid
                    },
                ],
            },
        })
    }, [])

    useEffect(() => {
        formDataDispatch({
            type: 'SET_FIELD',
            payload: { name: 'addressStreet', value: street },
        })
    }, [street])

    useEffect(() => {
        formDataDispatch({
            type: 'SET_FIELD',
            payload: { name: 'addressSecondary', value: secondary },
        })
    }, [secondary])

    useEffect(() => {
        formDataDispatch({
            type: 'SET_FIELD',
            payload: { name: 'addressCity', value: city },
        })
    }, [city])

    useEffect(() => {
        formDataDispatch({
            type: 'SET_FIELD',
            payload: { name: 'addressState', value: state },
        })
    }, [state])

    useEffect(() => {
        formDataDispatch({
            type: 'SET_FIELD',
            payload: { name: 'addressZip', value: zip },
        })
    }, [zip])

    useEffect(() => {
        if (!isManualEntry || !selectedStreet) return

        const asyncWrapper = async () => {
            const containsSecondaryError = analysisResult.some(
                (r) => r.startsWith('secondary_invalid') || r.startsWith('required_secondary')
            )

            if (containsSecondaryError) return
            setIsBannerVisible(false)
            await onNext()
        }

        asyncWrapper()
    }, [selectedStreet])

    useEffect(() => {
        if (isCheckinAddress || isManualEntry) return

        const asyncWrapper = async () => {
            try {
                if (street) {
                    const suggestions = await querySmartyAutoComplete(street)
                    const filteredAddresses = Object.values(
                        suggestions.reduce<Record<string, AddressAutocomplete>>((acc, address) => {
                            const key = `${address.streetLine}-${address.city}-${address.state}-${address.zipcode}`
                            if (!acc[key] || address.entries > acc[key].entries) {
                                acc[key] = address
                            }
                            return acc
                        }, {})
                    )
                    setSuggestedAddresses(filteredAddresses.slice(0, 5))
                }
            } catch (error) {
                setSuggestedAddresses([])
                setClickedStreetSuggestion(true)
            }
        }

        if (street.length === 1) {
            asyncWrapper()
            return () => {}
        }

        const timer = setTimeout(() => {
            asyncWrapper()
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [street, validation?.errors?.errorMessages?.addressStreet, isCheckinAddress])

    useEffect(() => {
        if (isCheckinAddress || isManualEntry || !isSecondaryRequired) return

        const asyncWrapper = async () => {
            if (secondary) {
                const suggestions = await getSmartySuggestion({
                    input: { street, secondary },
                    selected: {
                        street: selectedStreet?.streetLine ?? '',
                        secondary: secondary.trim(),
                        city: selectedStreet?.city ?? '',
                        state: selectedStreet?.state ?? '',
                        zip: selectedStreet?.zipcode ?? '',
                        entries: 5,
                    },
                })
                setSuggestedSecondaries(suggestions.slice(0, 5))
            }
        }

        if (secondary.length === 1) {
            asyncWrapper()
            return () => {}
        }

        const timer = setTimeout(() => {
            asyncWrapper()
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [secondary, validation?.errors?.errorMessages?.addressSecondary, isCheckinAddress])

    // clean the state on manual entry change
    useEffect(() => {
        setStreet('')
        setSecondary('')
        setCity('')
        setState('')
        setZip('')
        setIsSecondaryRequired(false)
        setSuggestedAddresses([])
        setSuggestedSecondaries([])
        setSelectedStreet(undefined)
        setAnalysisResult([])

        if (isManualEntry) {
            setIsSecondaryShowing(true)
            setIsSecondaryExpanded(false)
        } else {
            setIsSecondaryShowing(false)
            setIsSecondaryExpanded(false)
        }

        validationDispatch({
            type: 'ADD_ERROR',
            payload: {
                ...validation?.errors,
                errorMessages: {
                    addressStreet: '',
                    addressSecondary: '',
                    addressCity: '',
                    addressState: '',
                    addressZip: '',
                },
            },
        })
    }, [isManualEntry])

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isSecondaryRequired) {
            // Useful when secondary is hidden.
            // This will defer the focus action to the next event loop tick in case the state updates
            // the isSecondaryRequired, showSecondary and focus in the same call loop!
            const timeoutId = setTimeout(() => {
                secondaryFieldRef.current?.focus()
            }, 0)

            return () => clearTimeout(timeoutId)
        }
    }, [isSecondaryRequired, isSecondaryExanded, isSecondaryShowing])

    useEffect(() => {
        if (!popup.componentToRender) {
            setIsCheckingAddress(false)
        }
    }, [popup])

    useEffect(() => {
        if (numberOfFails >= 2) {
            setIsBannerVisible(true)
        }
    }, [numberOfFails])

    useEffect(() => {
        if (numberOfFails > 2 && !isBannerVisible) {
            setIsBannerVisible(true)
            formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: 'helpBannerAppearances',
                    value: formData.helpBannerAppearances ? formData.helpBannerAppearances + 1 : 1,
                },
            })
        }
    }, [numberOfFails])

    const changeShowBanner = (value: boolean) => {
        setIsBannerVisible(value)
    }

    return (
        <Page>
            {isBannerVisible && (
                <div
                    className={styles.twMerge(
                        styles?.steps?.welcomeXAddress?.notificationPill?.container?.mobile,
                        styles?.steps?.welcomeXAddress?.notificationPill?.container?.tablet,
                        styles?.steps?.welcomeXAddress?.notificationPill?.container?.desktop
                    )}
                >
                    <NotificationPill changeShowNotification={changeShowBanner}>
                        <FaPhone className="size-[16px]" />
                        <BodyText2
                            className={styles.twMerge(
                                styles?.steps?.welcomeXAddress?.notificationPill?.text?.mobile,
                                styles?.steps?.welcomeXAddress?.notificationPill?.text?.tablet,
                                styles?.steps?.welcomeXAddress?.notificationPill?.text?.desktop
                            )}
                        >
                            <StyleCopy
                                onLinkClick={() => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: {
                                            name: 'clickedBannerHelpLink',
                                            value: formData.clickedBannerHelpLink
                                                ? formData.clickedBannerHelpLink + 1
                                                : 1,
                                        },
                                    })
                                }}
                            >
                                {parseCopy(copy?.steps?.welcomeXAddress?.needHelp, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.welcomeXAddress,
                                })}
                            </StyleCopy>
                        </BodyText2>
                    </NotificationPill>
                </div>
            )}
            <Container
                className={styles.twMerge(
                    styles?.steps?.welcomeXAddress?.container?.mobile,
                    styles?.steps?.welcomeXAddress?.container?.tablet,
                    styles?.steps?.welcomeXAddress?.container?.desktop,
                    'py-4 lg:py-8'
                )}
            >
                <Main>
                    <img
                        className={styles.twMerge(
                            styles?.steps?.welcomeXAddress?.main?.logo?.mobile,
                            styles?.steps?.welcomeXAddress?.main?.logo?.tablet,
                            styles?.steps?.welcomeXAddress?.main?.logo?.desktop
                        )}
                        src={logo?.url}
                        alt={logo?.alt}
                    />
                    <SubHeading className="mb-2 text-center" name="addressBasicSubHeader">
                        <StyleCopy>{copy?.steps?.welcomeXAddress?.title}</StyleCopy>
                    </SubHeading>
                    <img
                        className={styles.twMerge(
                            styles?.steps?.welcomeXAddress?.main?.heroImage?.mobile,
                            styles?.steps?.welcomeXAddress?.main?.heroImage?.tablet,
                            styles?.steps?.welcomeXAddress?.main?.heroImage?.desktop
                        )}
                        src={image?.url}
                        alt={image?.alt}
                    />

                    {/* Fields */}
                    <TextFieldV2
                        ref={primaryFieldRef}
                        step={step.currentStep}
                        autoComplete="off"
                        placeholder="123 Main St"
                        disableOnChangeValidation
                        validationFunctions={[requireNonEmptyValue]}
                        formDataKey="addressStreet"
                        name="addressStreet"
                        value={street}
                        onChange={(value: any, isPreviousValue: boolean) => {
                            setStreet(value)
                            if (!isPreviousValue) {
                                setClickedStreetSuggestion(false)
                            }
                            setClickedSecondarySuggestion(false)
                            setIsSecondaryRequired(false)

                            if (!isManualEntry) {
                                setSelectedStreet(undefined)
                                setSecondary('')
                                setCity('')
                                setState('')
                                setZip('')
                                setIsSecondaryExpanded(false)
                                setIsSecondaryShowing(false)
                                validationDispatch({
                                    type: 'ADD_ERROR',
                                    payload: {
                                        ...validation?.errors,
                                        errorMessages: {
                                            addressStreet: '',
                                            addressSecondary: '',
                                            addressCity: '',
                                            addressState: '',
                                            addressZip: '',
                                        },
                                    },
                                })
                            } else {
                                validationDispatch({
                                    type: 'ADD_ERROR',
                                    payload: {
                                        ...validation?.errors,
                                        errorMessages: {
                                            ...validation?.errors?.errorMessages,
                                            addressStreet: '',
                                        },
                                    },
                                })
                            }
                        }}
                        disableDropdown={
                            isManualEntry || clickedStreetSuggestion || !street || suggestedAddresses.length === 0
                        }
                        dropdown={
                            <div>
                                {suggestedAddresses.map((suggestion: any) => {
                                    const addressId = `${suggestion.streetLine}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}`
                                    const id = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}${suggestion?.entries ? `, ${suggestion.entries}` : ''}`
                                    return (
                                        <Button
                                            type="textFieldDropdown"
                                            name={addressId}
                                            key={id}
                                            onClick={() => {
                                                const fullAddressString = `${suggestion.streetLine}, ${suggestion.city}, ${suggestion.state}`

                                                if (isManualEntry) {
                                                    setStreet(suggestion.streetLine)
                                                } else {
                                                    setStreet(fullAddressString)
                                                }
                                                setSecondary(`${suggestion.secondary} `)
                                                setCity(suggestion.city)
                                                setState(suggestion.state)
                                                setZip(suggestion.zipcode)
                                                setClickedStreetSuggestion(true)
                                                setSuggestedAddresses([])
                                                setSelectedStreet(suggestion)
                                                if (suggestion.entries) {
                                                    setIsSecondaryExpanded(true)
                                                    setIsSecondaryShowing(true)
                                                    setIsSecondaryRequired(true)
                                                }
                                                validationDispatch({
                                                    type: 'ADD_ERROR',
                                                    payload: {
                                                        ...validation?.errors,
                                                        errorMessages: {
                                                            ...validation?.errors?.errorMessages,
                                                            addressStreet: '',
                                                        },
                                                    },
                                                })
                                            }}
                                        >
                                            <svg
                                                className={styles.twMerge(
                                                    styles?.ui?.AddressFieldV2?.streetDropdownSvg?.mobile,
                                                    styles?.ui?.AddressFieldV2?.streetDropdownSvg?.tablet,
                                                    styles?.ui?.AddressFieldV2?.streetDropdownSvg?.desktop,
                                                    styles?.ui?.AddressFieldV2?.streetDropdownSvg?.hover,
                                                    'shrink-0'
                                                )}
                                                width="26"
                                                height="26"
                                                viewBox="0 0 26 26"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="13" cy="13" r="13" fill="#F4F4F5" />
                                                <path
                                                    d="M12.9997 4C9.68607 4 7 6.7966 7 10.2466C7 10.8394 7.08126 11.4124 7.22936 11.9554C7.32041 12.2902 7.42069 12.622 7.57801 12.9226C8.80838 15.268 12.9997 21.9994 12.9997 21.9994C12.9997 21.9994 17.1916 15.2674 18.422 12.9226C18.5793 12.622 18.679 12.2902 18.7706 11.9554C18.9187 11.4118 19 10.8394 19 10.2466C19 6.7966 16.3134 4 12.9997 4ZM12.9997 12.9976C12.1629 12.9976 11.4161 12.5902 10.932 11.956C10.573 11.4862 10.358 10.8922 10.358 10.2472C10.358 8.7304 11.5434 7.4968 13.0003 7.4968C14.4571 7.4968 15.642 8.731 15.642 10.2472C15.642 10.8928 15.427 11.4868 15.068 11.956C14.5833 12.5902 13.8371 12.9976 13.0003 12.9976H12.9997Z"
                                                    fill="#151515"
                                                />
                                            </svg>
                                            <div
                                                className={styles.twMerge(
                                                    styles?.ui?.AddressFieldV2?.streetDropdownItem?.mobile,
                                                    styles?.ui?.AddressFieldV2?.streetDropdownItem?.tablet,
                                                    styles?.ui?.AddressFieldV2?.streetDropdownItem?.desktop,
                                                    styles?.ui?.AddressFieldV2?.streetDropdownItem?.hover
                                                )}
                                            >
                                                <BodyText2>{suggestion.streetLine}</BodyText2>
                                                <BodyText4
                                                    className={styles.twMerge(
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSmallItem?.mobile,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSmallItem?.tablet,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSmallItem?.desktop,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSmallItem?.hover
                                                    )}
                                                >{`${suggestion?.city ? `${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}`}</BodyText4>
                                            </div>
                                            {suggestion.entries > 0 && (
                                                <TitleText2
                                                    className={styles.twMerge(
                                                        styles?.ui?.AddressFieldV2?.secondaryRequiredText?.mobile,
                                                        styles?.ui?.AddressFieldV2?.secondaryRequiredText?.tablet,
                                                        styles?.ui?.AddressFieldV2?.secondaryRequiredText?.desktop
                                                    )}
                                                >
                                                    {
                                                        copy?.steps?.welcomeXAddress?.addressFields?.street
                                                            ?.secondaryRequired
                                                    }
                                                </TitleText2>
                                            )}
                                        </Button>
                                    )
                                })}
                            </div>
                        }
                    >
                        {copy?.steps?.welcomeXAddress?.addressFields?.street?.label}
                    </TextFieldV2>

                    <ConditionalRender
                        condition={isSecondaryExanded}
                        falseReturn={
                            isSecondaryShowing && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsSecondaryExpanded(true)
                                    }}
                                >
                                    <p
                                        className={styles.twMerge(
                                            styles?.ui?.AddressFieldV2?.showSecondaryButton?.mobile,
                                            styles?.ui?.AddressFieldV2?.showSecondaryButton?.tablet,
                                            styles?.ui?.AddressFieldV2?.showSecondaryButton?.desktop
                                        )}
                                    >
                                        {copy?.steps?.welcomeXAddress?.addAppartmentUnit}
                                    </p>
                                </button>
                            )
                        }
                    >
                        <TextFieldV2
                            ref={secondaryFieldRef}
                            step={step.currentStep}
                            autoComplete="off"
                            placeholder="Apt 123"
                            disableOnChangeValidation
                            validationFunctions={[]}
                            formDataKey="addressSecondary"
                            name="addressSecondary"
                            value={secondary}
                            onChange={(value: any, isPreviousValue: boolean) => {
                                setSecondary(value)
                                if (!isPreviousValue) {
                                    setClickedSecondarySuggestion(false)
                                }
                                validationDispatch({
                                    type: 'ADD_ERROR',
                                    payload: {
                                        ...validation?.errors,
                                        errorMessages: {
                                            ...validation?.errors?.errorMessages,
                                            addressSecondary: '',
                                        },
                                    },
                                })
                            }}
                            disableDropdown={clickedSecondarySuggestion || !secondary || isManualEntry}
                            dropdown={
                                <div
                                    className={styles.twMerge(
                                        styles?.ui?.AddressFieldV2?.streetDropdown?.mobile,
                                        styles?.ui?.AddressFieldV2?.streetDropdown?.tablet,
                                        styles?.ui?.AddressFieldV2?.streetDropdown?.desktop
                                    )}
                                >
                                    {suggestedSecondaries.map((suggestion: any) => {
                                        const addressId = `${suggestion.streetLine}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}`
                                        const id = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}${suggestion?.entries ? `, ${suggestion.entries}` : ''}`
                                        return (
                                            <Button
                                                type="textFieldDropdown"
                                                name={addressId}
                                                key={id}
                                                onClick={() => {
                                                    setSecondary(suggestion.secondary)
                                                    setClickedSecondarySuggestion(true)
                                                    setSuggestedSecondaries([])
                                                    setSuggestedAddresses([])
                                                    validationDispatch({
                                                        type: 'ADD_ERROR',
                                                        payload: {
                                                            ...validation?.errors,
                                                            errorMessages: {
                                                                ...validation?.errors?.errorMessages,
                                                                addressSecondary: '',
                                                            },
                                                        },
                                                    })
                                                }}
                                            >
                                                <svg
                                                    className={styles.twMerge(
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSvg?.mobile,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSvg?.tablet,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSvg?.desktop,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownSvg?.hover
                                                    )}
                                                    width="26"
                                                    height="26"
                                                    viewBox="0 0 26 26"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle cx="13" cy="13" r="13" fill="#F4F4F5" />
                                                    <path
                                                        d="M12.9997 4C9.68607 4 7 6.7966 7 10.2466C7 10.8394 7.08126 11.4124 7.22936 11.9554C7.32041 12.2902 7.42069 12.622 7.57801 12.9226C8.80838 15.268 12.9997 21.9994 12.9997 21.9994C12.9997 21.9994 17.1916 15.2674 18.422 12.9226C18.5793 12.622 18.679 12.2902 18.7706 11.9554C18.9187 11.4118 19 10.8394 19 10.2466C19 6.7966 16.3134 4 12.9997 4ZM12.9997 12.9976C12.1629 12.9976 11.4161 12.5902 10.932 11.956C10.573 11.4862 10.358 10.8922 10.358 10.2472C10.358 8.7304 11.5434 7.4968 13.0003 7.4968C14.4571 7.4968 15.642 8.731 15.642 10.2472C15.642 10.8928 15.427 11.4868 15.068 11.956C14.5833 12.5902 13.8371 12.9976 13.0003 12.9976H12.9997Z"
                                                        fill="#151515"
                                                    />
                                                </svg>
                                                <div
                                                    className={styles.twMerge(
                                                        styles?.ui?.AddressFieldV2?.streetDropdownItem?.mobile,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownItem?.tablet,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownItem?.desktop,
                                                        styles?.ui?.AddressFieldV2?.streetDropdownItem?.hover
                                                    )}
                                                >
                                                    <BodyText2>{suggestion.secondary}</BodyText2>
                                                </div>
                                            </Button>
                                        )
                                    })}
                                </div>
                            }
                        >
                            {isSecondaryRequired
                                ? `${copy?.steps?.welcomeXAddress?.addressFields?.line2?.label}*`
                                : copy?.steps?.welcomeXAddress?.addressFields?.line2?.label}
                        </TextFieldV2>
                    </ConditionalRender>

                    <ConditionalRender
                        condition={isManualEntry}
                        falseReturn={
                            <div
                                className={styles.twMerge(
                                    styles?.ui?.AddressFieldV2?.enterAddressManuallyButton?.mobile,
                                    styles?.ui?.AddressFieldV2?.enterAddressManuallyButton?.tablet,
                                    styles?.ui?.AddressFieldV2?.enterAddressManuallyButton?.desktop
                                )}
                            >
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setIsManualEntry(true)
                                    }}
                                >
                                    {copy?.steps?.welcomeXAddress.enterAddressManually}
                                </Button>
                            </div>
                        }
                    >
                        <TextFieldV2
                            step={step.currentStep}
                            autoComplete="off"
                            placeholder="Anytown"
                            disableOnChangeValidation
                            validationFunctions={[]}
                            formDataKey="addressCity"
                            name="addressCity"
                            value={city}
                            onChange={(value: any) => {
                                setCity(value)
                                validationDispatch({
                                    type: 'ADD_ERROR',
                                    payload: {
                                        ...validation?.errors,
                                        errorMessages: {
                                            ...validation?.errors?.errorMessages,
                                            addressCity: '',
                                        },
                                    },
                                })
                            }}
                        >
                            {copy?.steps?.welcomeXAddress?.addressFields?.city?.label}
                        </TextFieldV2>

                        <div
                            className={styles.twMerge(
                                styles?.ui?.AddressFieldV2?.zipStateContainer?.mobile,
                                styles?.ui?.AddressFieldV2?.zipStateContainer?.tablet,
                                styles?.ui?.AddressFieldV2?.zipStateContainer?.desktop,
                                styles?.ui?.AddressFieldV2?.zipStateContainer?.hover
                            )}
                        >
                            <DropdownV2
                                step={step.currentStep}
                                hideErrorMessage
                                disableOnChangeValidation
                                clearErrorOnChange
                                // disableValidation={street !== '' && street !== null && street !== undefined}
                                validationFunctions={[]}
                                formDataKey="addressState"
                                name="addressState"
                                setValueTo={state}
                                options={usStates}
                                onChange={(value: any) => {
                                    setState(value)
                                    validationDispatch({
                                        type: 'ADD_ERROR',
                                        payload: {
                                            ...validation?.errors,
                                            errorMessages: {
                                                ...validation?.errors?.errorMessages,
                                                addressState: '',
                                            },
                                        },
                                    })
                                }}
                                className={styles.twMerge(
                                    styles?.ui?.AddressFieldV2?.stateField?.mobile,
                                    styles?.ui?.AddressFieldV2?.stateField?.tablet,
                                    styles?.ui?.AddressFieldV2?.stateField?.desktop,
                                    styles?.ui?.AddressFieldV2?.stateField?.hover,
                                    'w-full'
                                )}
                            >
                                {copy?.steps?.welcomeXAddress?.addressFields?.state?.label}
                            </DropdownV2>

                            <TextFieldV2
                                hideErrorMessage
                                phoneNumber
                                isNumberType
                                step={step.currentStep}
                                autoComplete="off"
                                placeholder="12345"
                                disableOnChangeValidation
                                validationFunctions={[]}
                                formDataKey="addressZip"
                                name="addressZip"
                                value={zip}
                                onChange={(value: any) => {
                                    setZip(value)
                                    validationDispatch({
                                        type: 'ADD_ERROR',
                                        payload: {
                                            ...validation?.errors,
                                            errorMessages: {
                                                ...validation?.errors?.errorMessages,
                                                addressZip: '',
                                            },
                                        },
                                    })
                                }}
                            >
                                {copy?.steps?.welcomeXAddress?.addressFields?.zipCode?.label}
                            </TextFieldV2>
                        </div>
                        <ConditionalRender
                            condition={
                                validation?.errors?.errorMessages?.addressZip === 'required' ||
                                validation?.errors?.errorMessages?.addressState === 'required'
                            }
                        >
                            <BodyText2
                                className={styles.twMerge(
                                    styles?.ui?.TextField?.error?.mobile,
                                    styles?.ui?.TextField?.error?.tablet,
                                    styles?.ui?.TextField?.error?.desktop
                                )}
                            >
                                <AiOutlineExclamationCircle
                                    className={styles.twMerge(
                                        styles?.ui?.TextField?.errorIcon?.mobile,
                                        styles?.ui?.TextField?.errorIcon?.tablet,
                                        styles?.ui?.TextField?.errorIcon?.desktop
                                    )}
                                />
                                &nbsp;{copy?.steps?.welcomeXAddress?.stateAndZipMissingError}
                            </BodyText2>
                        </ConditionalRender>

                        <div
                            className={styles.twMerge(
                                styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.container?.mobile,
                                styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.container?.tablet,
                                styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.container?.desktop
                            )}
                        >
                            <Button type="link" onClick={() => setIsManualEntry(false)}>
                                <div
                                    className={styles.twMerge(
                                        styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.innerContainer?.mobile,
                                        styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.innerContainer?.tablet,
                                        styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.innerContainer?.desktop
                                    )}
                                >
                                    <img
                                        className={styles.twMerge(
                                            styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.image?.mobile,
                                            styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.image?.tablet,
                                            styles?.ui?.AddressFieldV2?.returnToAutocompleteButton?.image?.desktop
                                        )}
                                        src={icon?.url}
                                        alt={icon?.alt}
                                    />
                                    {copy?.steps?.welcomeXAddress?.returnAutocomplete}
                                </div>
                            </Button>
                        </div>
                    </ConditionalRender>
                    <StepperButtonContainer>
                        <Button
                            type="fill"
                            className=""
                            name="welcomeXAddressNext"
                            onClick={async () => {
                                let errors: {
                                    errorMessages: { [key: string]: any }
                                    metaDatas: { [key: string]: any }
                                } = {
                                    errorMessages: {},
                                    metaDatas: {},
                                }

                                if (!formData.addressStreet) {
                                    errors = {
                                        errorMessages: {
                                            ...errors.errorMessages,
                                            addressStreet: isManualEntry
                                                ? 'requiredStreetManual'
                                                : 'requiredStreetAutocomplete',
                                        },
                                        metaDatas: {
                                            ...errors.metaDatas,
                                            addressStreet: {},
                                        },
                                    }

                                    if (!isManualEntry) {
                                        validationDispatch({
                                            type: 'ADD_ERROR',
                                            payload: errors,
                                        })
                                        setNumberOfFails((prev) => prev + 1)
                                        return
                                    }
                                }

                                if (!formData.addressCity && isManualEntry) {
                                    errors = {
                                        errorMessages: {
                                            ...errors.errorMessages,
                                            addressCity: 'requiredCity',
                                        },
                                        metaDatas: {
                                            ...errors.metaDatas,
                                            addressCity: {},
                                        },
                                    }
                                }

                                if (!formData.addressState && isManualEntry) {
                                    errors = {
                                        errorMessages: {
                                            ...errors.errorMessages,
                                            addressState: 'required',
                                        },
                                        metaDatas: {
                                            ...errors.metaDatas,
                                            addressState: {},
                                        },
                                    }
                                }

                                if (!formData.addressZip && isManualEntry) {
                                    errors = {
                                        errorMessages: {
                                            ...errors.errorMessages,
                                            addressZip: 'required',
                                        },
                                        metaDatas: {
                                            ...errors.metaDatas,
                                            addressZip: {},
                                        },
                                    }
                                }

                                setSuggestedAddresses([])
                                setSuggestedSecondaries([])
                                setIsCheckingAddress(true)

                                const { result, addressSuggested, candidate } = await validateAddress({
                                    street: selectedStreet ? selectedStreet.streetLine : street,
                                    city: selectedStreet ? selectedStreet.city : city,
                                    secondary,
                                    state: selectedStreet ? selectedStreet.state : state,
                                    zipcode: selectedStreet ? selectedStreet.zipcode : zip,
                                })

                                setAnalysisResult(result)

                                // check if address is perfect match!
                                if (addressSuggested && result.some((dv) => dv.startsWith('perfect_match'))) {
                                    // Check if the user input matches smarty format
                                    // if it doesn't the popup will show up
                                    const smartyAddressString = !isManualEntry
                                        ? `${candidate?.deliveryLine1}, ${candidate?.components.cityName}, ${candidate?.components.state}`
                                        : `${candidate?.deliveryLine1}, ${candidate?.components.cityName}, ${candidate?.components.state}, ${candidate?.components.zipCode}`
                                    const inputComps = street.split(',').filter(Boolean)
                                    const inputAddressString = !isManualEntry
                                        ? `${inputComps[0]}${secondary !== '' && isSecondaryRequired ? ` ${secondary.trim()}` : ''},${inputComps[1]},${inputComps[2]}`
                                        : `${street}${secondary && secondary !== '' ? ` ${secondary.trim()}` : ''}, ${city}, ${state}, ${zip}`

                                    if (smartyAddressString !== inputAddressString) {
                                        if (!isManualEntry) {
                                            popupDispatch({
                                                type: 'ADD_POPUP',
                                                payload: {
                                                    name: 'addressAutoComplete',
                                                    componentToRender: 'addressAutoComplete',
                                                    suggestion: addressSuggested,
                                                    onNext: async () => {
                                                        setIsBannerVisible(false)
                                                        await onNext()
                                                    },
                                                },
                                            })
                                        } else {
                                            setNumberOfFails((prev) => prev + 1)
                                            popupDispatch({
                                                type: 'ADD_POPUP',
                                                payload: {
                                                    name: 'addressSuggestion',
                                                    componentToRender: 'addressSuggestion',
                                                    input: {
                                                        street,
                                                        secondary,
                                                        city,
                                                        state,
                                                        zip,
                                                    },
                                                    onNext: async () => {
                                                        setIsBannerVisible(false)
                                                        formDataDispatch({
                                                            type: 'SET_FIELD',
                                                            payload: {
                                                                name: 'addressStreet',
                                                                value: addressSuggested.street,
                                                            },
                                                        })
                                                        formDataDispatch({
                                                            type: 'SET_FIELD',
                                                            payload: {
                                                                name: 'addressSecondary',
                                                                value: addressSuggested.secondary,
                                                            },
                                                        })
                                                        formDataDispatch({
                                                            type: 'SET_FIELD',
                                                            payload: {
                                                                name: 'addressCity',
                                                                value: addressSuggested.city,
                                                            },
                                                        })
                                                        formDataDispatch({
                                                            type: 'SET_FIELD',
                                                            payload: {
                                                                name: 'addressState',
                                                                value: addressSuggested.state,
                                                            },
                                                        })
                                                        formDataDispatch({
                                                            type: 'SET_FIELD',
                                                            payload: {
                                                                name: 'addressZip',
                                                                value: addressSuggested.zip,
                                                            },
                                                        })
                                                        setSelectedStreet({
                                                            streetLine: addressSuggested.street,
                                                            secondary: addressSuggested.secondary,
                                                            city: addressSuggested.city,
                                                            state: addressSuggested.state,
                                                            zipcode: addressSuggested.zip,
                                                            entries: 5,
                                                        })
                                                    },
                                                    suggestion: addressSuggested,
                                                },
                                            })
                                        }
                                        return
                                    }

                                    setIsBannerVisible(false)
                                    await onNext()
                                    return
                                }

                                if (result.some((r) => r.startsWith('not_found')) && !isManualEntry) {
                                    errors = {
                                        errorMessages: {
                                            ...errors.errorMessages,
                                            addressStreet: isManualEntry
                                                ? 'requiredStreetManual'
                                                : 'requiredStreetAutocomplete',
                                        },
                                        metaDatas: {
                                            ...errors.metaDatas,
                                            addressStreet: {},
                                        },
                                    }
                                    setNumberOfFails((prev) => prev + 1)
                                    validationDispatch({
                                        type: 'ADD_ERROR',
                                        payload: errors,
                                    })
                                    setIsCheckingAddress(false)
                                    return
                                }

                                // secondary is required
                                if (result.some((r) => r.startsWith('required_secondary'))) {
                                    setIsSecondaryExpanded(true)
                                    setIsSecondaryShowing(true)
                                    setIsSecondaryRequired(true)

                                    const secondarySuggestion = await querySmartyAutoComplete(addressSuggested?.street)
                                    const suggestionWithSecondaryInfo = secondarySuggestion.find(
                                        (suggestion) =>
                                            suggestion.city === addressSuggested?.city &&
                                            suggestion.state === addressSuggested?.state &&
                                            suggestion.secondary !== ''
                                    )
                                    setSelectedStreet(suggestionWithSecondaryInfo)
                                    setSecondary(
                                        suggestionWithSecondaryInfo?.secondary
                                            ? `${suggestionWithSecondaryInfo?.secondary} `
                                            : ''
                                    )
                                    setIsCheckingAddress(false)
                                    secondaryFieldRef.current?.focus()

                                    if (result.some((r) => r.startsWith('secondary_invalid'))) {
                                        errors = {
                                            errorMessages: {
                                                ...errors.errorMessages,
                                                addressSecondary: 'existingAddressLine2',
                                            },
                                            metaDatas: {
                                                ...errors.metaDatas,
                                                addressSecondary: {},
                                            },
                                        }

                                        setNumberOfFails((prev) => prev + 1)
                                        validationDispatch({
                                            type: 'ADD_ERROR',
                                            payload: errors,
                                        })

                                        return
                                    }

                                    errors = {
                                        errorMessages: {
                                            ...errors.errorMessages,
                                            addressSecondary: 'validAddressLine2',
                                        },
                                        metaDatas: {
                                            ...errors.metaDatas,
                                            addressSecondary: {},
                                        },
                                    }

                                    setNumberOfFails((prev) => prev + 1)
                                    validationDispatch({
                                        type: 'ADD_ERROR',
                                        payload: errors,
                                    })
                                    return
                                }

                                if (isManualEntry && !addressSuggested) {
                                    if (result.some((r) => r.startsWith('non_residential'))) {
                                        errors = {
                                            errorMessages: {
                                                addressStreet: 'requireResidential',
                                            },
                                            metaDatas: {
                                                addressStreet: {},
                                            },
                                        }
                                        setNumberOfFails((prev) => prev + 1)
                                        validationDispatch({
                                            type: 'ADD_ERROR',
                                            payload: errors,
                                        })
                                        setIsCheckingAddress(false)
                                        return
                                    }

                                    // check if there's empty fields
                                    const hasErrors = Object.values(errors.errorMessages).some(
                                        (value) => value !== '' && value !== null && value !== undefined
                                    )

                                    if (hasErrors) {
                                        setNumberOfFails((prev) => prev + 1)
                                        validationDispatch({
                                            type: 'ADD_ERROR',
                                            payload: errors,
                                        })
                                        setIsCheckingAddress(false)
                                        return
                                    }

                                    setNumberOfFails((prev) => prev + 1)
                                    popupDispatch({
                                        type: 'ADD_POPUP',
                                        payload: {
                                            name: 'addressNotFound',
                                            componentToRender: 'addressNotFound',
                                            input: {
                                                street,
                                                secondary,
                                                city,
                                                state,
                                                zip,
                                            },
                                        },
                                    })

                                    return
                                }

                                if (!addressSuggested) {
                                    errors = {
                                        errorMessages: {
                                            ...errors.errorMessages,
                                            addressStreet: result.some((r) => r.startsWith('non_residential'))
                                                ? 'requireResidential'
                                                : 'requiredStreetAutocomplete',
                                        },
                                        metaDatas: {
                                            ...errors.metaDatas,
                                            addressStreet: {},
                                        },
                                    }
                                    setNumberOfFails((prev) => prev + 1)
                                    validationDispatch({
                                        type: 'ADD_ERROR',
                                        payload: errors,
                                    })
                                    setIsCheckingAddress(false)
                                    primaryFieldRef.current?.focus()
                                    setStreet((prev) => prev.trim())
                                    return
                                }

                                if (isManualEntry) {
                                    setNumberOfFails((prev) => prev + 1)
                                    popupDispatch({
                                        type: 'ADD_POPUP',
                                        payload: {
                                            name: 'addressSuggestion',
                                            componentToRender: 'addressSuggestion',
                                            input: {
                                                street,
                                                secondary,
                                                city,
                                                state,
                                                zip,
                                            },
                                            suggestion: addressSuggested,
                                            onNext: async () => {
                                                setIsBannerVisible(false)
                                                await onNext()
                                            },
                                        },
                                    })
                                    return
                                }

                                setNumberOfFails((prev) => prev + 1)
                                popupDispatch({
                                    type: 'ADD_POPUP',
                                    payload: {
                                        name: 'addressAutoComplete',
                                        componentToRender: 'addressAutoComplete',
                                        suggestion: addressSuggested,
                                        onNext: async () => {
                                            setIsBannerVisible(false)
                                            await onNext()
                                        },
                                    },
                                })
                            }}
                        >
                            {isCheckinAddress ? (
                                <div className="flex justify-center w-full">
                                    <TbLoader2 size={28} className="animate-spin" />
                                </div>
                            ) : (
                                copy?.steps?.welcomeXAddress?.submitButton
                            )}
                        </Button>
                    </StepperButtonContainer>
                </Main>
            </Container>
        </Page>
    )
}

export default WelcomeXAddress
