// Step Action Functions
import addressBasic from './addressBasic'
import addressAutocomplete from './addressAutocomplete'
import interimFormP2Branching from './interimFormP2Branching'
import interimP2ExistingCustomerQuestion from './interimP2ExistingCustomerQuestion'
import defaultAction from './default'
import addLeadToConnex from './addLeadToConnex'
import orderSubmitLog from './orderSubmitLog'
import addToManychatAndCnx from './addToManychatAndCnx'
import manychatExistingCustomerQuestion from './manychatExistingCustomerQuestion'

const stepActions: { [key: string]: Function } = {
    addressBasic,
    addressAutocomplete,
    interimFormP2Branching,
    interimP2ExistingCustomerQuestion,
    defaultAction,
    addLeadToConnex,
    orderSubmitLog,
    addToManychatAndCnx,
    manychatExistingCustomerQuestion,
}

const getStepActions = ({ key }: { key: string }) => {
    return stepActions?.[key] ? stepActions[key] : defaultAction
}

export default getStepActions
