/* eslint-disable no-unused-vars */
// Helpers
import { log } from '../logging'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'Address Autocomplete Step Action',
        message: 'Ran onNext',
    })
    const addressStreet = params.formData?.addressStreetSmartyResponse?.data?.delivery_line_1
    const addressSecondary = params.formData?.addressStreetSmartyResponse?.data?.delivery_line_2
    const addressCity = params.formData?.addressStreetSmartyResponse?.data?.components?.city_name
    const addressState = params.formData?.addressStreetSmartyResponse?.data?.components?.state_abbreviation
    const addressZip = params.formData?.addressStreetSmartyResponse?.data?.components?.zipcode
    const rdi = params.formData?.addressStreetSmartyResponse?.data?.metadata?.rdi
    let addressType = rdi
    if (
        rdi === 'Residential' &&
        !params.formData?.addressStreetSmartyResponse?.data?.components?.secondary_designator
    ) {
        addressType = 'House'
    } else if (
        rdi === 'Residential' &&
        params.formData?.addressStreetSmartyResponse?.data?.components?.secondary_designator
    ) {
        addressType = 'Apartment'
    }
    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'addressStreet',
            value: `${addressStreet}`,
        },
    })

    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'addressSecondary',
            value: `${addressSecondary ? `, ${addressSecondary}` : ''}`,
        },
    })
    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'addressCity',
            value: `${addressCity}`,
        },
    })
    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'addressState',
            value: `${addressState}`,
        },
    })
    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'addressZip',
            value: `${addressZip}`,
        },
    })
    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'addressType',
            value: `${addressType}`,
        },
    })
    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'fullAddress',
            value: `${addressStreet}${addressSecondary ? `, ${addressSecondary}` : ''}${addressCity ? `, ${addressCity}` : ''}${addressState ? `, ${addressState}` : ''}${addressZip ? `, ${addressZip}` : ''}`,
        },
    })
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'Address Autocomplete Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'Address Autocomplete Step Action',
        message: 'Ran onSubmit',
    })
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
